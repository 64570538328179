import React, {useCallback, useEffect, useRef} from 'react';
import {useTheme} from '@mui/material';
import {merge, cloneDeep} from 'lodash';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import HighCharts from 'highcharts';
import HighChartsReact from 'highcharts-react-official';
import {defaultHighChartsOptionsJson} from './highChartsOptionsJson';
import {openInOneTab} from '../../../apps/dashboard/utils';
import {withCurrentUser} from '../../contexts/currentUserContext';

const createDefaultHighChartsJson = (
  getDataCall,
  graphSettings,
  tooltipProvider,
  theme,
  currentUser,
) => {
  return Object.assign(
    {},
    {
      chart: {
        type: graphSettings.chartType,
        events: {
          drilldown: (e) => {
            return e.point.nextStateId && getDataCall(e);
          },
        },
      },
      title: {
        text: graphSettings.title,
        margin: parseFloat(theme.spacing(5)),
      },
      subtitle: {
        text: graphSettings.subtitle,
        style: {
          color: theme.palette.primary.light,
          fontSize: theme.typography.caption.fontSize,
        },
      },
      xAxis: {
        title: graphSettings.xAxisTitle,
      },
      yAxis: {
        title: {
          text: graphSettings.yAxisTitle,
        },
      },
      plotOptions: {
        // General settings for all series
        series: {},
        // General settings for columns
        column: {
          minPointLength: 5,
          color: graphSettings.columnColor,
          pointPadding: parseFloat(theme.spacing(0)),
          states: {
            hover: {
              color: graphSettings.hoverColor,
            },
          },
          point: {
            events: {
              click() {
                if (this.options && this.options.url) {
                  window.open(this.options.url, openInOneTab(currentUser));
                }
              },
            },
          },
        },
      },
    },
    // If tooltipProvider exist we will add it to the json, else default tooltip
    tooltipProvider
      ? {
          tooltip: {
            formatter: function tooltipWrapper() {
              return tooltipProvider(this, graphSettings.tipText);
            },
            useHTML: true,
          },
        }
      : {},
  );
};

const createHighChartJson = (
  getDataCall,
  extraOptions,
  graphSettings,
  tooltipProvider,
  theme,
  currentUser,
) => {
  const initialJson = createDefaultHighChartsJson(
    getDataCall,
    graphSettings,
    tooltipProvider,
    theme,
    currentUser,
  );

  const extraOptionsJson = extraOptions ? extraOptions(theme) : {};

  return merge(
    cloneDeep(defaultHighChartsOptionsJson),
    cloneDeep(extraOptionsJson),
    cloneDeep(initialJson),
  );
};

const useHighchartsApi = (
  apiFn,
  params,
  formatData,
  chartComponent,
  graphSettings,
  formatDualAxes,
  theme,
) => {
  return useCallback(
    async (...args) => {
      const {
        current: {chart},
      } = chartComponent;
      chart.showLoading();

      let isDrilldown = false;
      try {
        await apiFn(params, ...args).then((response) => {
          const {results} = response;
          const formattedResults = formatData
            ? results.map((element) => {
                return formatData(element);
              })
            : results;

          isDrilldown = args && args[0] ? args[0].type === 'drilldown' : false;

          // check if point is a drilldown point
          if (isDrilldown) {
            const {
              point,
              point: {name},
            } = args[0];
            chart.addSeriesAsDrilldown(point, {
              name,
              data: formattedResults,
            });
          } else {
            // if args we are no drilldown but chart is in drilldown state, it was refreshed or filter was applied
            if (chart.drilldownLevels) {
              // force chart to drillup and then add data
              chart.drillUp();
            }

            // remove older series if it exists
            while (chart.series.length) {
              chart.series[0].remove();
            }

            // removes any older plot lines if reloaded
            chart.yAxis[0].removePlotLine('avg-plot-line');

            // Add new data
            chart.addSeries({
              data: formattedResults,
            });

            if (formatDualAxes) {
              chart.addSeries(formatDualAxes(results, theme));
            }

            chart.yAxis[0].addPlotLine({
              id: 'avg-plot-line',
              value: response.avgValue,
              dashStyle: 'dot',
              color: 'gray',
              label: {
                text: graphSettings.plotLinesLabel,
                align: 'right',
                x: -10,
                style: {
                  fontSize: 12,
                  pointerEvents: 'none',
                },
              },
              zIndex: 4,
            });
          }

          if (formattedResults.length === 0) {
            chart.showNoData();
          }
        });
      } catch (e) {
        if (!isDrilldown) {
          chart.hideNoData();
          chart.showNoData('Error loading data');
        }
      } finally {
        chart.hideLoading();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFn, chartComponent, formatData, params],
  );
};

const HighChartsWidget = (props) => {
  const chartComponent = useRef(null);
  const theme = useTheme();
  const {
    getData,
    params,
    formatData,
    formatDualAxes,
    extraOptions,
    graphSettings,
    tooltipProvider,
    currentUser,
  } = props;

  // Obtains top-level data
  const getDataCall = useHighchartsApi(
    getData,
    params,
    formatData,
    chartComponent,
    graphSettings,
    formatDualAxes,
    theme,
  );

  // Creates options json for highcharts
  const chartOptionsJson = createHighChartJson(
    getDataCall,
    extraOptions,
    graphSettings,
    tooltipProvider,
    theme,
    currentUser,
  );

  // Get Top level data
  useEffect(
    () => {
      getDataCall().then(() => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params?.filters],
  );

  return (
    <HighChartsReact
      id={`${graphSettings.widgetName}-id`}
      key={graphSettings.widgetName}
      ref={chartComponent}
      highcharts={HighCharts}
      options={chartOptionsJson}
    />
  );
};

export default compose(withCurrentUser)(HighChartsWidget);

HighChartsWidget.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  graphSettings: PropTypes.shape({
    chartType: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    xAxisTitle: PropTypes.string,
    plotLinesLabel: PropTypes.string,
    columnColor: PropTypes.string,
    hoverColor: PropTypes.string,
    widgetName: PropTypes.string,
  }).isRequired,
  getData: PropTypes.func.isRequired,
  params: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  formatData: PropTypes.func,
  formatDualAxes: PropTypes.func,
  extraOptions: PropTypes.func,
  tooltipProvider: PropTypes.func,
};

HighChartsWidget.defaultProps = {
  params: null,
  formatData: null,
  formatDualAxes: null,
  extraOptions: null,
  tooltipProvider: null,
};
