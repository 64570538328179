import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography, Divider, Switch} from '@mui/material';
import {useDialog} from '../../../../shared/hooks';
import {
  AlarmModeDisarmed,
  AlarmModeEventsOnly,
  AlarmModeTest,
  AlarmModeEMArmed,
  AlarmModeSelfVerifiedArmed,
} from './AlarmMode';
import {
  CameraEnabledPrerequisite,
  EnvysionMonitoringSubscriberPrerequisite,
  NotificationSubscriberPrerequisite,
  ScheduleSetPrerequisite,
} from './AlarmModePrerequisite';
import AlarmModesWarningDialog from './AlarmModesWarningDialog';

const AlarmModeContent = ({
  snackbar,
  alarmModeState,
  onCamerasConfigurationOpen,
  onSchedulesConfigurationOpen,
  onNotificationsConfigurationOpen,
  onAlarmModeUpdate,
  updateAlarmStatus,
}) => {
  const modes = alarmModeState.siteStatus.statuses;
  const {isEnvysionMonitoring} = alarmModeState.siteStatus;
  const [warningDialogInput, setWarningDialogInput] = useState({
    action: null,
    selectedMode: null,
    data: null,
  });

  const [
    alarmModeWarningDialogOpen,
    handleAlarmModeWarningDialogOpen,
    handleAlarmModeWarningDialogClose,
  ] = useDialog();

  const getModeData = (mode) => modes.find((value) => value.status === mode);

  const findActiveMode = (statuses) =>
    statuses.find((element) => element.isActive);

  const updateAlarmMode = async (mode) => {
    updateAlarmStatus(
      mode,
      () => {
        snackbar.success('Alarm mode successfully updated!');
        onAlarmModeUpdate(mode);
      },
      () => {
        snackbar.error('Alarm mode was not updated, please try again!');
      },
    );
  };

  const handleUpdateAlarmMode = (mode) => {
    const previousMode = findActiveMode(modes);
    setWarningDialogInput((p) => {
      return {
        ...p,
        action: `SwitchTo${mode.replace(/\s/g, '')}`,
        selectedMode: mode,
      };
    });
    if (previousMode.status !== mode) {
      if (mode !== 'Events Only') {
        handleAlarmModeWarningDialogOpen();
      } else {
        updateAlarmMode(mode, previousMode);
      }
    }
  };

  const handlePrerequisitesCheck = (prerequisitesMet, modeData) => {
    if (!prerequisitesMet) {
      setWarningDialogInput({
        action: `DisabledSwitchClick`,
        data: modeData.prerequisitesState,
      });
      handleAlarmModeWarningDialogOpen();
    }
  };

  const getSwitch = (mode) => {
    const modeData = getModeData(mode);
    const prerequisitesMet = Object.values(modeData.prerequisitesState).every(
      (value) => value === true,
    );
    return (
      <div
        aria-hidden="true"
        role="button"
        data-cy="switchWrapper"
        onClick={() => handlePrerequisitesCheck(prerequisitesMet, modeData)}
      >
        <Switch
          disabled={alarmModeState.state === 'SWITCHING' || !prerequisitesMet}
          data-cy={`switchTo${mode.replaceAll(' ', '')}`}
          name={`switchTo${mode.replaceAll(' ', '')}`}
          checked={getModeData(mode).isActive}
          onChange={() => handleUpdateAlarmMode(mode)}
        />
      </div>
    );
  };

  const getPrerequisites = (mode) => {
    const {prerequisitesState} = getModeData(mode);
    switch (mode) {
      case 'Events Only':
        return (
          <Fragment>
            <CameraEnabledPrerequisite
              state={prerequisitesState?.cameraEnabled}
              onCamerasConfigurationOpen={onCamerasConfigurationOpen}
            />
            <ScheduleSetPrerequisite
              state={prerequisitesState?.scheduleSet}
              onSchedulesConfigurationOpen={onSchedulesConfigurationOpen}
            />
          </Fragment>
        );
      case 'Test Mode':
        return (
          <EnvysionMonitoringSubscriberPrerequisite
            state={prerequisitesState?.envysionMonitoringSubscriber}
            onNotificationsConfigurationOpen={onNotificationsConfigurationOpen}
          />
        );
      case 'Armed':
        return (
          !isEnvysionMonitoring && (
            <NotificationSubscriberPrerequisite
              state={prerequisitesState?.selfMonitoringSubscriber}
              onNotificationsConfigurationOpen={
                onNotificationsConfigurationOpen
              }
            />
          )
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <Typography
        display="flex"
        alignItems="center"
        sx={{
          mt: 3,
          mb: 2,
          p: 1,
          backgroundColor: 'grey.300',
          ...(theme) => ({
            height: theme.spacing(5),
          }),
        }}
        variant="body1"
      >
        Change mode
      </Typography>
      <AlarmModeDisarmed label="Disarm" actionContent={getSwitch('Disarmed')} />
      <Divider sx={{mb: 2}} />
      <AlarmModeEventsOnly
        actionContent={getSwitch('Events Only')}
        extraContent={getPrerequisites('Events Only')}
      />
      <Divider sx={{mb: 2}} />
      {isEnvysionMonitoring ? (
        <Fragment>
          <AlarmModeTest
            actionContent={getSwitch('Test Mode')}
            extraContent={getPrerequisites('Test Mode')}
          />
          <Divider sx={{mb: 2}} />
          <AlarmModeEMArmed label="Arm" actionContent={getSwitch('Armed')} />
        </Fragment>
      ) : (
        <AlarmModeSelfVerifiedArmed
          label="Arm"
          actionContent={getSwitch('Armed')}
          extraContent={getPrerequisites('Armed')}
        />
      )}
      <AlarmModesWarningDialog
        data-cy="alarmModeWarningDialog"
        open={alarmModeWarningDialogOpen}
        onClose={handleAlarmModeWarningDialogClose}
        onConfirm={() =>
          updateAlarmMode(
            warningDialogInput.selectedMode,
            findActiveMode(modes),
          )
        }
        isEnvysionMonitoring={isEnvysionMonitoring}
        warningDialogInput={warningDialogInput}
        onCamerasConfigurationOpen={onCamerasConfigurationOpen}
        onNotificationsConfigurationOpen={onNotificationsConfigurationOpen}
        onSchedulesConfigurationOpen={onSchedulesConfigurationOpen}
      />
    </Fragment>
  );
};

AlarmModeContent.propTypes = {
  snackbar: PropTypes.shape({}).isRequired,
  alarmModeState: PropTypes.shape({
    state: PropTypes.string.isRequired,
    siteStatus: PropTypes.shape({
      isEnvysionMonitoring: PropTypes.bool.isRequired,
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
  onCamerasConfigurationOpen: PropTypes.func.isRequired,
  onSchedulesConfigurationOpen: PropTypes.func.isRequired,
  onNotificationsConfigurationOpen: PropTypes.func.isRequired,
  onAlarmModeUpdate: PropTypes.func.isRequired,
  updateAlarmStatus: PropTypes.func.isRequired,
};

export default AlarmModeContent;
