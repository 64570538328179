import React, {useEffect, useState} from 'react';
import {Grid, useMediaQuery, useTheme} from '@mui/material';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

import TabBar from '../../shared/components/tabBar';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const getIframeTab = (theme, isMediumScreen, path, title, frameProps) => {
  return (
    <Grid item xs={12}>
      <IFrameWrapper
        src={`https://${getHost()}/clips2/${path}`}
        title={title}
        FrameProps={{
          style: {
            border: 0,
            height: `calc(100vh - ${theme.mixins.toolbar.height +
              (isMediumScreen
                ? 0
                : theme.mixins.bottomNavigationBar.height)}px - 96px)`,
          },
          ...frameProps,
        }}
      />
    </Grid>
  );
};

const getTabs = (theme, isMediumScreen) => [
  {
    key: 'root',
    path: 'root',
    label: 'My Clips',
    component: () =>
      getIframeTab(theme, isMediumScreen, 'root', 'My Clips', {
        sandbox: 'allow-same-origin allow-scripts allow-downloads',
      }),
  },
  {
    key: 'external-shares',
    path: 'external-shares',
    label: 'External Shares',
    component: () =>
      getIframeTab(
        theme,
        isMediumScreen,
        'external-shares',
        'External Shares',
        {
          sandbox:
            'allow-scripts allow-same-origin allow-downloads allow-popups',
        },
      ),
  },
  {
    key: 'trash',
    path: 'trash',
    label: 'Trash',
    component: () =>
      getIframeTab(theme, isMediumScreen, 'trash', 'Trash', {
        sandbox: 'allow-scripts allow-same-origin allow-downloads',
      }),
  },
];
const getCurrentTabKeys = (tabKeys, history) =>
  tabKeys.find((tab) => history.location.pathname.includes(tab)) ?? 'root';

const ClipsPageResponsive = () => {
  const theme = useTheme();
  const history = useHistory();
  const isMediumScreen = useMediaQuery((t) => t.breakpoints.up('md'));
  const tabKeys = getTabs(theme, isMediumScreen).map((tab) => tab.key);

  const currentTabKey = getCurrentTabKeys(tabKeys, history);
  const currentTabIndex = tabKeys.indexOf(currentTabKey);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(
    () => {
      setSelectedTab(currentTabIndex !== -1 ? currentTabIndex : 0);
    },
    [currentTabIndex],
  );

  const handleTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      history.push('/home/my-clips');
    } else {
      history.push(`/home/my-clips/${tabKeys[tabIndex]}`);
    }
    setSelectedTab(tabIndex);
  };

  return (
    <Grid container data-cy="clips-content">
      <Grid item xs={12}>
        <TabBar
          onChange={(index) => handleTabSelected(index)}
          tabs={getTabs(theme, isMediumScreen)}
          selectedTab={selectedTab}
        />
      </Grid>
    </Grid>
  );
};

export default ClipsPageResponsive;
