import {getHost} from './hostUtil';

const captureTargetQueryParam = (paths) => {
  const {pathname, searchParams} = new URL(window.location.href);
  const matchedPath = paths.find((path) => pathname.includes(`/${path}`));
  return matchedPath
    ? {match: matchedPath, target: searchParams.get('targetContent')}
    : null;
};

const handleAdditionalParams = (params, updatedSrcUrl, cacheValue) => {
  let urlToReturn = updatedSrcUrl;
  if (params) {
    urlToReturn = `${urlToReturn}${
      urlToReturn.includes('?') ? '&' : '?'
    }${params}`;
  }
  return (urlToReturn = `${urlToReturn}${
    urlToReturn.includes('?') ? '&' : '?'
  }cache=${cacheValue}`);
};

const buildUrl = (src, target, match) => {
  const adminPaths = ['admin/accessList', 'admin/posList', 'admin/exportList'];
  const appPaths = ['admin/audits', 'operations/projects', 'samples/report'];

  let targetUrl = target.startsWith('/') ? target : `/${target}`;
  if (targetUrl.endsWith('/')) {
    targetUrl = targetUrl.slice(0, -1);
  }

  // handle admin paths
  if (adminPaths.some((x) => x === match)) {
    return `https://${getHost()}/admin${targetUrl}`;
  }

  // handle app/#/sample paths
  if (appPaths.some((x) => x === match)) {
    return `https://${getHost()}/app/#/samples${targetUrl}`;
  }

  // handle ops-ui paths
  if (match === 'ops/orders') {
    return `https://${getHost()}/ops${targetUrl}`;
  }

  // handle clips paths
  if (match === 'my-clips') {
    return `https://${getHost()}/clips2${targetUrl}`;
  }

  return `${src}${targetUrl}`;
};

const targetPaths = (src) => [
  `https://${getHost()}/admin`,
  `https://${getHost()}/app/#/samples`,
  `https://${getHost()}/ops`,
  src,
];

const pathsRegex = (src) =>
  new RegExp(
    targetPaths(src)
      .map((path) => path.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|'),
  );

export const getTargetContent = (newUrl, src, setUrlState) => {
  if (newUrl.includes('/clips2')) {
    const basePath = `https://${getHost()}/clips2/`;

    const relativePath = newUrl.replace(basePath, '');

    if (!relativePath) {
      setUrlState(
        {
          targetContent: undefined,
        },
        'replace',
        true,
      );
      return undefined;
    }

    const [subPath, ...rest] = relativePath.split('/');

    if (subPath === 'trash' || subPath === 'external-shares') {
      if (rest.length > 0) {
        return rest.join('/');
      }
      setUrlState(
        {
          targetContent: undefined,
        },
        'replace',
        true,
      );
      return undefined;
    }
    return relativePath;
  }
  return newUrl.split(pathsRegex(src))[1];
};

export const buildFrameUrl = (paths, src, params, cacheValue) => {
  let updatedSrcUrl = src;
  const {match = null, target = null} = captureTargetQueryParam(paths) || {};
  if (target) {
    updatedSrcUrl = buildUrl(src, target, match);
  }
  updatedSrcUrl = handleAdditionalParams(params, updatedSrcUrl, cacheValue);
  return updatedSrcUrl;
};

export const removeParams = (url, paramsToRemove) => {
  const pattern = new RegExp(`[&?](${paramsToRemove.join('|')})=[^&#]*`, 'g');
  let modifiedUrl = url.replace(pattern, '');
  modifiedUrl = modifiedUrl.replace(/([?&])$/, '');
  modifiedUrl = modifiedUrl.replace(/[?&]{2,}/, (match) => match[0]);

  return modifiedUrl;
};
