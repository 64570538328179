import React from 'react';
import DashboardAgeProgressionWidget from '../../../shared/components/dashboardComponent/dashboardAgeProgressionWidget';
import {widgetConfigPropType} from '../types';
import {useWidgetApi, useWidgetProps} from './hooks';

const AgeProgressionWidget = (props) => {
  const {widgetConfig} = props;
  const [results, loading, error] = useWidgetApi(
    widgetConfig.widgetReference,
    // filtering.filters,
  );

  const {actions, widgetProps} = useWidgetProps(widgetConfig);

  return (
    <DashboardAgeProgressionWidget
      title={widgetConfig.title}
      loading={loading}
      ages={results?.ages || []}
      rows={results?.rows || []}
      error={error}
      actions={actions}
      {...widgetProps}
    />
  );
};

AgeProgressionWidget.propTypes = {
  widgetConfig: widgetConfigPropType.isRequired,
};

export default AgeProgressionWidget;
