import React, {useMemo} from 'react';
import merge from 'lodash/merge';
import {useDrilldown, useWidgetApi, useWidgetProps} from './hooks';
import {filteringPropType, widgetConfigPropType} from '../types';
import DashboardChartWidget from '../../../shared/components/dashboardComponent/dashboardChartWidget';

const ChartWidget = (props) => {
  const {widgetConfig, filtering} = props;
  const [results, loading, error] = useWidgetApi(
    widgetConfig.widgetReference,
    filtering?.filters,
  );

  const {chartOptions: configChartOptions} = widgetConfig.properties;

  const data = results?.data;

  const chartOptions = useMemo(() => merge({data}, configChartOptions ?? {}), [
    data,
    configChartOptions,
  ]);

  const {actions, widgetDrilldownActions, widgetProps} = useWidgetProps(
    widgetConfig,
  );

  const [handleDrilldown] = useDrilldown(
    widgetDrilldownActions,
    filtering?.filters,
    1,
    'custom.drilldown',
  );

  return (
    <DashboardChartWidget
      title={widgetConfig.title}
      loading={loading}
      id={`${widgetConfig.widgetReference}_${widgetConfig.position}`}
      error={error}
      {...widgetProps}
      chartOptions={chartOptions}
      actions={actions}
      onChildDrilldown={(evt) => handleDrilldown(evt.point)}
    />
  );
};

ChartWidget.propTypes = {
  widgetConfig: widgetConfigPropType.isRequired,
  filtering: filteringPropType.isRequired,
};

export default ChartWidget;
