import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import {kebabCase} from 'lodash';
import compose from 'lodash/flowRight';
import Spinner from '../spinner';
import {buildFrameUrl, getTargetContent, removeParams} from './util';
import withUrlState from '../withUrlState';
import {getHostEnv} from '../../util/general';
import {incidentsHosts} from '../navigationBar/utils';

// track additional 'redirect' path segments for iFramed repositories, for now we handle these:
export const trackablePaths = [
  'incidents',
  'operations/projects',
  'ops/orders',
  'admin/audits',
  'samples/report',
  'admin/accessList',
  'admin/posList',
  'admin/exportList',
  'my-clips/root',
  'my-clips/external-shares',
  'my-clips/trash',
  'my-clips',
];

const IFrameWrapper = ({src, title, FrameProps, params, setUrlState}) => {
  const [loading, setLoading] = useState(true);
  const [cacheValue] = useState(new Date().getTime());
  const [updatedSrcUrl, setUpdatedSrcUrl] = useState(null);

  const onFinishLoad = () => setLoading(false);

  useEffect(() => {
    const scrUrl = buildFrameUrl(trackablePaths, src, params, cacheValue);
    setUpdatedSrcUrl(scrUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for url changes inside iframe page to update targetContent search param
  useEffect(
    () => {
      const messageHandler = (event) => {
        if (
          (event.origin === document.location.origin ||
            event.origin === incidentsHosts[getHostEnv()]) &&
          event.data.type === 'hostUrl'
        ) {
          const newUrl = removeParams(event.data.url, ['cache']);
          const currentTargetContent = new URLSearchParams(
            window.location.search,
          ).get('targetContent');
          if (newUrl !== undefined) {
            const targetContent = getTargetContent(newUrl, src, setUrlState);
            if (
              targetContent !== '/' &&
              targetContent !== currentTargetContent &&
              targetContent
            ) {
              setUrlState({targetContent}, 'replace', true);
            }
          }
        }
      };
      window.addEventListener('message', messageHandler);

      return () => {
        window.removeEventListener('message', messageHandler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    updatedSrcUrl && (
      <Fragment>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {loading && <Spinner size={40} color="primary" />}
        </Box>
        <iframe
          key={cacheValue}
          title={title}
          src={updatedSrcUrl.toString()}
          width="100%"
          height="100%"
          allowFullScreen
          style={{border: 0}}
          onLoad={onFinishLoad}
          data-cy={`${kebabCase(title)}-iframe-content`}
          {...FrameProps}
        />
      </Fragment>
    )
  );
};

IFrameWrapper.propTypes = {
  /**
   *   Should contain full path of page to display inside frame
   */
  src: PropTypes.string.isRequired,
  /**
   *   IFrame element should have title.
   *   Title should  be unique to easily find iframe in component tree
   */
  title: PropTypes.string.isRequired,
  /**
   *   Optional styling props for iFrame
   */
  FrameProps: PropTypes.shape({}),
  /**
   *   Additional params to include in url
   */
  params: PropTypes.string,
};

IFrameWrapper.defaultProps = {
  FrameProps: {},
  params: undefined,
};

export default compose(withUrlState())(IFrameWrapper);
