import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PropTypes} from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import SectionTitleBar from '../SectionTitleBar';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  accordionSummary: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  accordionSummaryContent: {
    display: 'flex',
    flexFlow: 'column',
    margin: theme.spacing(0),
    '&.Mui-expanded': {
      margin: theme.spacing(0),
    },
  },
  accordionDetails: {
    display: 'inherit',
    padding: theme.spacing(0),
  },
}));

const AlarmConfigurationAccordion = (props) => {
  const classes = useStyles();

  const {summaryContent, detailedContent, headerTitle, headerIcon} = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummary,
          content: classes.accordionSummaryContent,
        }}
      >
        <div className={classes.title} data-cy="header-title">
          <SectionTitleBar title={headerTitle} icon={headerIcon} />
          {expanded ? (
            <ExpandLessIcon data-cy="icon-expanded" color="secondary" />
          ) : (
            <ExpandMoreIcon data-cy="icon-collapsed" color="secondary" />
          )}
        </div>

        {summaryContent}
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        {detailedContent}
      </AccordionDetails>
    </Accordion>
  );
};

AlarmConfigurationAccordion.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerIcon: PropTypes.node.isRequired,
  detailedContent: PropTypes.node,
  summaryContent: PropTypes.node,
};

AlarmConfigurationAccordion.defaultProps = {
  detailedContent: <div />,
  summaryContent: <div />,
};

export default AlarmConfigurationAccordion;
