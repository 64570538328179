import React from 'react';
import PropTypes from 'prop-types';
import GlobalErrorPage from '../globalErrorPage';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const PageIFrameWrapper = (
  path,
  sandbox,
  capability,
  title,
  pageName = '',
  host = `https://${getHost()}`,
  params = '',
  pillarName = '',
) => {
  const url = `${host}${path}`;

  return (
    <CapabilitiesGuard
      allowed={capability}
      renderDefault={() => <GlobalErrorPage error="NoAuth" />}
    >
      <PageTemplateResponsive
        PageContentProps={{
          marginY: 0,
          marginX: 0,
        }}
        pillarName={pillarName}
        defaultPageTitle={pageName}
      >
        <IFrameWrapper
          src={url}
          title={title}
          FrameProps={{sandbox}}
          params={params}
        />
      </PageTemplateResponsive>
    </CapabilitiesGuard>
  );
};

PageIFrameWrapper.propTypes = {
  /**
   *   Should contain path of page to iframe.
   *   Based on host and path, url is created and passed to iframe
   */
  path: PropTypes.string.isRequired,
  /**
   *   Sandbox attribute passed to iframe
   */
  sandbox: PropTypes.string.isRequired,
  /**
   *   User's capabilities needed to display page
   */
  capability: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   *   IFrame element should have title.
   *   Title should  be unique to easily find iframe in component tree
   */
  title: PropTypes.string.isRequired,
  /**
   *   Page name displayed in browser's tab
   */
  pageName: PropTypes.string,
  /**
   *   Based on host and path, url is created and passed to iframe
   */
  host: PropTypes.string.isRequired,
  /**
   *   Additional params to include in url
   */
  params: PropTypes.string,
  /**
   *   If page is located inside pillar, add pillar's name
   */
  pillarName: PropTypes.string,
};

PageIFrameWrapper.defaultProps = {
  pageName: '',
  params: '',
  pillarName: '',
};
export default PageIFrameWrapper;
