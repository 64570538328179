import React, {Fragment, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import NotificationsIcon from '@mui/icons-material/Notifications';
import {PropTypes} from 'prop-types';
import Spinner from '../../../../shared/components/spinner';
import NotificationDialog from './NotificationDialog';

import SectionTitleBar from '../SectionTitleBar';
import NotificationSection from './NotificationSection';
import {getSubscribersForSection, subscriptionEventTypes} from './utils';

import {useSspNotifications} from './hooks';

const NotificationAccordion = (props) => {
  const {
    site,
    isEnvysionMonitoring,
    notificationsDialogOpen,
    onNotificationsDialogOpen,
    onNotificationsDialogClose,
    hasVideoSubscription,
  } = props;
  const [subscriptionEventType, setSubscriptionEventType] = useState(
    subscriptionEventTypes.video,
  );

  const {
    loading,
    subscribers,
    hasDmpPanel,
    expanded,
    setExpanded,
  } = useSspNotifications(site?.id, notificationsDialogOpen);

  const onNotificationEditOpen = (type) => () => {
    setSubscriptionEventType(type);
    onNotificationsDialogOpen();
  };

  return (
    site && (
      <Fragment>
        <Accordion
          elevation={0}
          expanded={expanded}
          disableGutters
          onChange={() => setExpanded((prev) => !prev)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="secondary" />}
            sx={{padding: 0}}
          >
            <SectionTitleBar
              title="SSP Notifications"
              icon={<NotificationsIcon />}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 0,
              }}
            />
          </AccordionSummary>
        </Accordion>
        {loading ? (
          <Spinner size={40} color="primary" />
        ) : (
          <Fragment>
            {hasVideoSubscription && (
              <NotificationSection
                expanded={expanded}
                sectionName="Video Alarm"
                subscribers={getSubscribersForSection(
                  subscriptionEventTypes.video,
                  subscribers,
                )}
                onNotificationsDialogOpen={onNotificationEditOpen(
                  subscriptionEventTypes.video,
                )}
                eventType={subscriptionEventTypes.video}
                isEM={isEnvysionMonitoring}
              />
            )}
            {hasDmpPanel && (
              <NotificationSection
                expanded={expanded}
                sectionName="DMP Panel"
                subscribers={getSubscribersForSection(
                  subscriptionEventTypes.panel,
                  subscribers,
                )}
                onNotificationsDialogOpen={onNotificationEditOpen(
                  subscriptionEventTypes.panel,
                )}
                eventType={subscriptionEventTypes.panel}
              />
            )}
          </Fragment>
        )}
        <NotificationDialog
          open={notificationsDialogOpen}
          onClose={onNotificationsDialogClose}
          site={site}
          eventType={subscriptionEventType}
          isEM={isEnvysionMonitoring}
        />
      </Fragment>
    )
  );
};

NotificationAccordion.propTypes = {
  site: PropTypes.shape({id: PropTypes.string}).isRequired,
  isEnvysionMonitoring: PropTypes.bool,
  notificationsDialogOpen: PropTypes.bool.isRequired,
  onNotificationsDialogOpen: PropTypes.func.isRequired,
  onNotificationsDialogClose: PropTypes.func.isRequired,
  hasVideoSubscription: PropTypes.bool.isRequired,
};
NotificationAccordion.defaultProps = {
  isEnvysionMonitoring: false,
};

export default NotificationAccordion;
