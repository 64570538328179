import React, {useRef, useEffect} from 'react';
import merge from 'lodash/merge';
import {useTheme} from '@mui/material';
import set from 'lodash/set';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import fromPairs from 'lodash/fromPairs';
import PropTypes from 'prop-types';
import HighCharts from 'highcharts';
import HighChartsReact from 'highcharts-react-official';

const slotStyles = [
  {slotName: 'title', defaultVariant: 'h3'},
  {
    slotName: 'subtitle',
    defaultVariant: 'subtitle1',
    defaultColor: 'secondary',
  },
  {
    slotName: 'caption',
    defaultVariant: 'caption',
    defaultColor: 'secondary',
  },
  {
    slotName: 'credits',
    defaultVariant: 'caption',
    defaultColor: 'secondary',
  },
  {
    slotName: 'tooltip',
    defaultVariant: 'caption',
    omitStyles: ['lineHeight'],
  },
  {slotName: 'noData', defaultVariant: 'subtitle2'},
  {slotName: 'loading', defaultVariant: 'h6'},
  {
    slotName: 'legend',
    defaultVariant: 'caption',
    stylePropName: 'itemStyle',
  },
  {
    slotName: 'drilldown.breadcrumbs.buttonTheme',
    defaultVariant: 'caption',
    additionalStyles: {
      color: '#334eff',
    },
  },
];

const acceptableStyleValues = (value) =>
  ['string', 'number'].includes(typeof value);

const slotStyle = (
  slotProps,
  theme,
  {
    slotName,
    defaultVariant,
    defaultColor,
    stylePropName = 'style',
    omitStyles = [],
    additionalStyles = {},
  } = {},
) => {
  return [
    slotName,
    {
      [stylePropName]: {
        ...pickBy(
          omit(
            theme.typography[
              slotProps[slotName]?.style?.variant ?? defaultVariant
            ],
            omitStyles,
          ),
          acceptableStyleValues,
        ),
        color:
          theme.palette.text[slotProps[slotName]?.style?.color ?? defaultColor],
        ...additionalStyles,
      },
    },
  ];
};

const BaseChart = (props) => {
  const chartComponent = useRef(null);
  const {data, title, subtitle, slotProps, loading, caption, ...rest} = props;

  const theme = useTheme();

  useEffect(
    () => {
      const {
        current: {chart},
      } = chartComponent;
      if (loading) {
        chart.showLoading();
      } else {
        chart.hideLoading();
      }
    },
    [loading],
  );

  const chartOptions = merge(
    // Basic chart options
    {
      title: {
        text: title,
      },
      subtitle: {
        text: subtitle,
      },
      caption: {
        text: caption,
      },
      credits: {
        enabled: false,
      },
    },
    // Styling
    {
      ...slotStyles
        .map((opts) => slotStyle(slotProps, theme, opts))
        .reduce((acc, [slotName, styles]) => {
          set(acc, slotName, styles);
          return acc;
        }, {}),
      chart: {
        backgroundColor:
          theme.palette.background[
            slotProps.chart?.backgroundColor ?? 'default'
          ],

        style: {
          ...pickBy(theme.typography, acceptableStyleValues),
        },
      },
    },
    // Other defaults
    {
      tooltip: {
        hideDelay: 0,
        borderRadius: theme.shape.borderRadius,
      },
    },
    data,
    // Escape hatch for overrides
    slotProps.chart ?? {},
  );

  return (
    <HighChartsReact
      {...rest}
      ref={chartComponent}
      highcharts={HighCharts}
      options={chartOptions}
    />
  );
};

BaseChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  caption: PropTypes.string,
  slotProps: PropTypes.shape({
    ...fromPairs(
      slotStyles.map((ss) => [
        ss.slotName,
        PropTypes.shape({
          style: PropTypes.shape({
            variant: PropTypes.string,
            color: PropTypes.string,
          }),
        }),
      ]),
    ),
    // eslint-disable-next-line react/forbid-prop-types
    chart: PropTypes.object,
  }),
  /* eslint-disable react/forbid-prop-types */
  data: PropTypes.shape({
    series: PropTypes.array,
    drilldown: PropTypes.shape({
      series: PropTypes.array,
    }),
  }),
  /* eslint-enable react/forbid-prop-types */
  loading: PropTypes.bool,
};

BaseChart.defaultProps = {
  data: undefined,
  title: undefined,
  subtitle: undefined,
  caption: undefined,
  slotProps: {},
  loading: false,
};

export default BaseChart;
