import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import merge from 'lodash/merge';

import DashboardWidget from '../../../../../shared/components/dashboardComponent/dashboardWidget';

const componentPropTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  column: PropTypes.exact({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.node,
    hidden: PropTypes.bool,
    format: PropTypes.func,
    headerFormat: PropTypes.func,
    /* eslint-disable react/forbid-prop-types */
    headerProps: PropTypes.object,
    cellProps: PropTypes.object,
    /* eslint-enable react/forbid-prop-types */
  }),
};

const EnhancedTableRow = (props) => {
  const {onRowClick, row, ...rest} = props;

  const tableRowProps = {
    sx: {
      '&:last-child td, &:last-child th': {border: 0},
    },
  };

  if (typeof onRowClick === 'function') {
    merge(tableRowProps, {
      sx: {
        cursor: 'pointer',
      },
      onClick: (evt) => onRowClick(evt, row),
      hover: true,
    });
  }

  return <TableRow {...rest} {...tableRowProps} />;
};

EnhancedTableRow.propTypes = {
  onRowClick: PropTypes.func,
  row: componentPropTypes.row.isRequired,
};

EnhancedTableRow.defaultProps = {
  onRowClick: undefined,
};

const ValueCell = (props) => {
  const {column, row, ...rest} = props;
  return (
    <TableCell {...rest} {...column.cellProps}>
      {typeof column.format === 'function'
        ? column.format(row[column.id], row)
        : row[column.id]}
    </TableCell>
  );
};

ValueCell.propTypes = {
  row: componentPropTypes.row.isRequired,
  column: componentPropTypes.column.isRequired,
};

const DashboardTableWidget = (props) => {
  const {columns, rows, onRowClick, heading, slotProps, ...rest} = props;

  return (
    <DashboardWidget
      {...rest}
      content={
        <Fragment>
          {heading}
          <Table size="small" {...slotProps.table ?? {}}>
            <TableHead>
              <TableRow {...slotProps.tableHeaderRow ?? {}}>
                {columns
                  .filter((c) => !c.hidden)
                  .map((column) => {
                    return (
                      <TableCell key={column.id} {...column.headerProps}>
                        {typeof column.headerFormat === 'function'
                          ? column.headerFormat(column, columns)
                          : column.label}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <EnhancedTableRow
                    {...slotProps.tableRow ?? {}}
                    key={row.id}
                    row={row}
                    onRowClick={onRowClick}
                  >
                    {columns.map((column) => {
                      return (
                        <ValueCell
                          {...slotProps.tableCell ?? {}}
                          key={`${row.id}-${column.id}`}
                          column={column}
                          row={row}
                        />
                      );
                    })}
                  </EnhancedTableRow>
                );
              })}
            </TableBody>
          </Table>
        </Fragment>
      }
    />
  );
};

DashboardTableWidget.propTypes = {
  onRowClick: PropTypes.func,
  heading: PropTypes.node,
  columns: PropTypes.arrayOf(componentPropTypes.column).isRequired,
  rows: PropTypes.arrayOf(componentPropTypes.row).isRequired,
  slotProps: PropTypes.shape({
    table: PropTypes.shape({}),
    tableHeaderRow: PropTypes.shape({}),
    tableRow: PropTypes.shape({}),
    tableCell: PropTypes.shape({}),
  }),
};

DashboardTableWidget.defaultProps = {
  onRowClick: undefined,
  heading: undefined,
  slotProps: {},
};

export default DashboardTableWidget;
