import React, {PureComponent} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import get from 'lodash/get';

import {getGroup, getMembers, getGroups} from '../../../api/groups';
import {getAllUsers} from '../../../api/users';
import PageLoader from '../../../shared/components/pageLoader';
import {formatGroupsData} from './helpers';

class GroupsRoutes extends PureComponent {
  state = {filterValue: ''};

  setRouteState = (stateChanges) => {
    this.setState(stateChanges);
  };

  render() {
    const {match} = this.props;

    const loaderProps = {
      ...this.state,
      setRouteState: this.setRouteState,
    };

    return (
      <Switch>
        <Route
          path={`${match.path}/:id`}
          render={({match: renderMatch}) => {
            const groupId = get(renderMatch, 'params.id');

            return (
              <PageLoader
                page={() => import('./Details')}
                resources={{
                  group: () => getGroup(groupId),
                  members: () => getMembers(groupId),
                  users: () => getAllUsers(),
                }}
              />
            );
          }}
        />
        <Route
          path={`${match.path}`}
          render={() => (
            <PageLoader
              page={() => import('./List')}
              resources={{
                groups: async () => formatGroupsData(await getGroups()),
              }}
              propsToIgnoreUpdate={['filterValue']}
              {...loaderProps}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(GroupsRoutes);
