import React from 'react';
import camelCase from 'lodash/camelCase';
import {DateTime} from 'luxon';
import {
  INCIDENT_MANAGEMENT_CREATE,
  INCIDENT_MANAGEMENT_USER_CREATE,
  INCIDENT_MANAGEMENT,
  hasCapability,
} from '../../../shared/util/allowed';
// Parse the column type returned from the report APIs into the desired format.
const isFormatted = (str, format) => {
  return DateTime.fromFormat(str, format).isValid;
};
const renderColumnType = (cellParams) => {
  const fieldType = cellParams.colDef.displayFormat;
  const fieldPrecision = cellParams.colDef.displayPrecision;
  if (fieldType === 'CURRENCY') {
    const commaDelimited = String(
      Math.abs(cellParams.value).toFixed(2),
    ).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return (
      <p>
        {cellParams.value < 0 ? '-' : ''}${commaDelimited}
      </p>
    );
  }

  // If already formatted yyyy-MM-dd dont try to format
  if (fieldType === 'DATE') {
    if (isFormatted(cellParams.value, 'yyyy-MM-dd')) {
      return <p>{cellParams.value}</p>;
    }
    // ISO String
    const formattedDate = DateTime.fromISO(cellParams.value).toFormat(
      'yyyy-MM-dd hh:mm:ss a',
    );
    return <p>{formattedDate}</p>;
  }
  if (fieldType === 'TIME') {
    if (isFormatted(cellParams.value, 'hh:mm:ss a')) {
      return <p>{cellParams.value}</p>;
    }
    // ISO String
    const formattedTime = DateTime.fromISO(cellParams.value).toFormat(
      'hh:mm:ss a',
    );
    return <p>{formattedTime}</p>;
  }
  if (fieldType === 'PERCENTAGE') {
    return (
      <p>
        {cellParams.value < 0 ? '-' : ''}
        {Math.abs(cellParams.value * 100).toFixed(2)}%
      </p>
    );
  }
  if (fieldType === 'DECIMAL') {
    if (fieldPrecision) {
      return <p>{cellParams?.value?.toFixed(fieldPrecision)}</p>;
    }
    return <p>{cellParams?.value?.toFixed(2)}</p>;
  }
  return <p>{cellParams.value}</p>;
};

// Helper function to format value
export const formatValue = (value, type) => {
  if (value === null || value === undefined || isNaN(value)) {
    return '-';
  }
  if (type === 'CURRENCY') {
    return `$${value?.toFixed(2)}`;
  }
  return value;
};

// Helper function to translate API column value to DataGrid
export const translateColumnsToDataGrid = (columns) => {
  return columns.map((column) => {
    const translatedColumn = {};
    translatedColumn.headerName = column.displayName || '';
    translatedColumn.field = camelCase(column.fieldName) || '';
    translatedColumn.flex = column.flex || 1;

    // Apply valueFormatter if necessary
    if (
      column.parameterType === 'CURRENCY' ||
      column.parameterType === 'INTEGER' ||
      column.parameterType === 'DECIMAL'
    ) {
      translatedColumn.valueFormatter = (params) =>
        formatValue(params.value, column.parameterType);
    }

    // Get proper type formatting
    translatedColumn.renderCell = renderColumnType;

    Object.assign(translatedColumn, column, translatedColumn);
    return translatedColumn;
  });
};

// Helper function to ensure all columns exist in rows
const ensureAllColumnsInRows = (rows, columns) => {
  if (!columns || !Array.isArray(columns)) {
    throw new Error('Columns must be an array');
  }

  return rows.map((row) => {
    const completeRow = {...row};
    columns.forEach((column) => {
      if (!(camelCase(column.fieldName) in completeRow)) {
        completeRow[camelCase(column.fieldName)] = null;
      }
    });
    return completeRow;
  });
};

// Helper function to translate API row value to DataGrid
export const translateRowsToDataGrid = (rows, columns) => {
  const rowsWithAllColumns = ensureAllColumnsInRows(rows, columns);
  return rowsWithAllColumns.map((row, index) => {
    const translatedRow = {id: index};
    Object.keys(row).forEach((key) => {
      translatedRow[key] = row[key];
    });
    return translatedRow;
  });
};

export const convertSortDirection = (str) => {
  switch (str) {
    case 'ASCENDING':
      return 'asc';
    case 'DESCENDING':
      return 'desc';
    case 'asc':
      return 'ASCENDING';
    case 'desc':
      return 'DESCENDING';
    default:
      return '';
  }
};

export const hasIncidentCreateCapabilities = (currentUser) => {
  return (
    hasCapability(currentUser, INCIDENT_MANAGEMENT_CREATE) ||
    hasCapability(currentUser, INCIDENT_MANAGEMENT_USER_CREATE)
  );
};

export const hasIncidentFlagCapabilities = (currentUser, isHiddenIncident) => {
  if (isHiddenIncident) {
    return (
      hasCapability(currentUser, INCIDENT_MANAGEMENT_CREATE) &&
      !hasCapability(currentUser, INCIDENT_MANAGEMENT_USER_CREATE)
    );
  }
  return hasCapability(currentUser, INCIDENT_MANAGEMENT);
};

export const hasIncidentInfoCapabilities = (currentUser, isHiddenIncident) => {
  if (isHiddenIncident) {
    return (
      hasCapability(currentUser, INCIDENT_MANAGEMENT) &&
      hasCapability(currentUser, INCIDENT_MANAGEMENT_CREATE) &&
      !hasCapability(currentUser, INCIDENT_MANAGEMENT_USER_CREATE)
    );
  }

  return hasCapability(currentUser, INCIDENT_MANAGEMENT);
};

export const getSiteNamesByStoreCode = (node) => ({
  ...node?.sites?.reduce((acc, c) => ({...acc, [c.id]: c}), {}),
  ...node?.categories?.reduce(
    (acc, c) => ({...acc, ...getSiteNamesByStoreCode(c)}),
    {},
  ),
});

const getStateWrapper = (report, fnc, stateId, rootStateId) => {
  let rootState = null;
  let state = null;

  // Function will get the report states
  const getState = (startState) => {
    const fncResult = fnc(startState, stateId);
    if (fncResult != null) {
      rootState = startState;
      state = fncResult;
      // If we get matching rootState and state we prefer the root for this case
      if (rootState === state || rootState.id === report.startStateId) {
        return false;
      }
    }
    return true;
  };

  // Check that rootStateId is in startStates
  const index = report?.startStates
    .map((startState) => startState.id)
    .indexOf(rootStateId);

  // Get Report states directly or iterating if it cant be found
  if (rootStateId != null && index !== -1) {
    getState(report?.startStates[index]);
  } else {
    report?.startStates.every((startState) => {
      return getState(startState);
    });
  }

  if (rootState != null || state != null) {
    const reportStartStateIndex = report.startStates
      .map((startState) => startState.id)
      .indexOf(rootState.id);

    const reportStartState = report.startStates[reportStartStateIndex];
    return {
      rootState: reportStartState ?? rootState,
      state: state ?? rootState,
    };
  }

  return null;
};

const getStateById = (state, stateId) => {
  if (state.id === stateId) {
    return state;
  }

  if (state.nextState != null) {
    return getStateById(state.nextState, stateId);
  }

  return null;
};

const getStateByDisplayName = (state, displayName) => {
  if (state.displayName === displayName) {
    return state;
  }

  if (state.nextState != null) {
    return getStateByDisplayName(state.nextState, displayName);
  }

  return null;
};

export const getReportStates = (user, report, stateId, rootStateId) => {
  let defaultStates = null;

  if (report !== null) {
    defaultStates = {
      rootState: report.startStates[0],
      state: report.startStates[0],
    };
  }

  // parse stateId if it is passed as a string
  const id = typeof stateId === 'string' ? Number(stateId) : stateId;

  // Get default start state with users preferences
  const reportStartsBy = user?.settings?.reportsStartBy;
  const defaultUserStates = getStateWrapper(
    report,
    getStateByDisplayName,
    reportStartsBy,
    rootStateId,
  );

  // Get report state with stateId passed defaults to reports startStateId
  const reportStates = getStateWrapper(
    report,
    getStateById,
    id ?? report?.startStateId,
    rootStateId,
  );

  return reportStates ?? defaultUserStates ?? defaultStates;
};
