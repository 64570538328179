import React, {useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import get from 'lodash/get';
import {getWidgetData} from '../../../api/reports';
import {useApi} from '../../../../shared/hooks';
import DashboardWidgetActionLink from '../../../shared/components/dashboardComponent/actions/link';

export const useWidgetApi = (widgetReference, filters) => {
  const [results, loading, error, call] = useApi(getWidgetData);

  useEffect(
    () => {
      call(widgetReference, filters);
    },
    [call, widgetReference, filters],
  );

  return [results, loading, error];
};

export const useWidgetProps = (widgetConfiguration) => {
  const {
    actions: widgetActions,
    filterDefs: widgetFilterDefs,
    drilldownActions: widgetDrilldownActions,
    ...widgetProps
  } = widgetConfiguration.properties;

  const actions = widgetActions?.map(({type, ...actionConfig}, idx) => {
    if (type === 'link') {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <DashboardWidgetActionLink key={`${type}_${idx}`} {...actionConfig} />
      );
    }
    return null;
  });
  return {actions, widgetProps, widgetDrilldownActions, widgetFilterDefs};
};

// TODO: does this make sense to be a hook?
export const useDrilldown = (
  actions,
  filters,
  // TODO: figure out how to make idx work better
  idx = 0,
  // TODO: should this be a function to get the drilldown field?
  drilldownField = 'drilldown',
) => {
  const handleReportDrilldown = (data) => {
    const filterOverrides = {
      paramName: {
        incidentTime: 'date',
      },
    };

    const filterParams = filters?.reduce((acc, curr) => {
      if (typeof curr.value === 'undefined' || isEmpty(curr.value)) {
        return acc;
      }
      const updateArray = (arrayName) => {
        const arr = acc[arrayName] ?? [];
        arr.push(
          filterOverrides[arrayName]?.[curr[arrayName]] ?? curr[arrayName],
        );
        return arr;
      };
      return {
        paramName: updateArray('paramName'),
        operatorType: updateArray('operatorType'),
        value: updateArray('value'),
      };
    }, {});

    const params = queryString.stringify({
      state_id: actions[idx].stateId,
      selectedTab: actions[idx].selectedTab,
      drilldown: get(data, drilldownField),
      ...filterParams,
    });

    window.open(
      `/ui/${actions[idx].pillar}/reports/${actions[idx].reportId}?${params}`,
      '_blank',
    );
  };

  // TODO: handle drilldown types for charts
  const handleDrilldown = (data) => {
    if (actions[idx].type === 'report') {
      return handleReportDrilldown(data);
    }
    return undefined;
  };

  return [handleDrilldown];
};
