import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {Link} from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';

const DashboardWidgetActionLink = (props) => {
  const {label, target, pathname, search} = props;

  return (
    <Button
      component={Link}
      data-cy="widget-action"
      to={{
        pathname,
        search,
        state: {fromDashboard: true},
      }}
      target={target}
      endIcon={
        target === '_blank' && <OpenInNewIcon data-cy="widget-action-icon" />
      }
    >
      {label}
    </Button>
  );
};

DashboardWidgetActionLink.propTypes = {
  label: PropTypes.string.isRequired,
  target: PropTypes.oneOf(['_self', '_blank']),
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
};

DashboardWidgetActionLink.defaultProps = {
  target: '_blank',
  search: undefined,
};

export default DashboardWidgetActionLink;
