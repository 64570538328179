import _ from 'lodash';
import {DateTime} from 'luxon';
import axios from 'axios';
import {formatValue} from '../../pages/reports/newReportsPage/utils';

const instance = axios.create();
instance.interceptors.request.eject(
  instance.interceptorIds?.requestCasingInterceptor,
);

export const getReportResults = async (reportId, options) => {
  if (reportId.startsWith('poc')) {
    return (await instance.get(`/lp-poc/reports/${reportId}/results`, options))
      .data;
  }
  return undefined;
};

export const getReportData = async (reportId) => {
  if (reportId.startsWith('poc')) {
    return (await instance.get(`/lp-poc/reports/${reportId}`)).data;
  }
  return undefined;
};

const now = DateTime.now();

// function to get incidents from api
// will need to pass params to it.
const getIncidents = async (filters) => {
  const params = filters.reduce(
    (acc, f) => {
      if (!['offsetDays', 'sites'].includes(f.paramName)) {
        acc.paramName.push(f.paramName);
        acc.operatorType.push(f.operatorType);
        acc.value.push(f.value);
      }
      return acc;
    },
    {
      paramName: [],
      operatorType: [],
      value: [],
    },
  );

  return (await instance.get(`/lp-poc/incidents`, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })).data;
};

const sumIncidentAmounts = (list) =>
  list.reduce((a, b) => a + (b.amount ?? 0.0), 0);

export const incidentAgeWidget = async () => {
  const ageBuckets = [1, 7, 14];

  const filteredIncidents = await getIncidents([]);

  const bucketIncidents = (incidentList) => {
    const groups = incidentList.reduce((acc, i) => {
      const daysDiff = now.diff(DateTime.fromISO(i.created), 'days').days;
      const buckets = _.takeRightWhile(
        ageBuckets,
        (value) => daysDiff <= value,
      );
      buckets.forEach((b) => {
        if (!acc[b]) {
          acc[b] = [];
        }
        acc[b].push(i);
      });
      return acc;
    }, {});

    return _.mapValues(groups, (g) => ({
      primary: g.length,
      secondary: formatValue(sumIncidentAmounts(g), 'CURRENCY'),
    }));
  };

  const createdBucket = bucketIncidents(filteredIncidents);
  const resolvedBucket = bucketIncidents(
    filteredIncidents.filter((i) => i.statusId === '2'),
  );

  return {
    ages: ageBuckets,
    rows: [
      {
        values: createdBucket,
        label: 'Created',
      },
      {
        values: resolvedBucket,
        label: 'Resolved',
      },
    ],
  };
};

export const incidentCategoryBreakdownWidget = async (filters) => {
  const filteredIncidents = await getIncidents(filters);

  const grouping = (items, childProps = []) => {
    const [groupByProp, ...restProps] = childProps;
    const grouped = _.groupBy(items, groupByProp);
    return _.sortBy(
      Object.entries(grouped).map(([key, v]) => {
        return {
          name: key,
          y: v.length,
          custom: {
            type: v[0].type,
            category: v[0].category,
            subcategory: v[0].subcategory,
            drilldown: `Category|${v[0].categoryId}:${v[0].subcategoryId}`,
          },
          children: !_.isEmpty(restProps) ? grouping(v, restProps) : undefined,
        };
      }),
      'custom.type',
    );
  };

  return {
    data: grouping(filteredIncidents, ['category', 'subcategory']),
  };
};

export const employeeIncidentsWidget = async (filters) => {
  const groupingKey = (i) => `${i.employee}`;

  const filteredIncidents = await getIncidents(filters);

  const grouping = _.groupBy(filteredIncidents, groupingKey);

  const rows = Object.values(grouping).map((empIncidents) => {
    return empIncidents.reduce(
      (acc, i) => {
        let {lastIncidentTime} = acc;
        if (
          !lastIncidentTime ||
          DateTime.fromISO(lastIncidentTime) < DateTime.fromISO(i.incidentTime)
        ) {
          lastIncidentTime = i.incidentTime;
        }
        return {
          id: groupingKey(i),
          amount: acc.amount + (i.amount ?? 0),
          employee: i.employee,
          lastIncidentTime,
          incidentCount: acc.incidentCount + 1,
          severity: acc.severity + i.severity,
          drilldown: `Employee|${i.employee}:${i.siteId}`,
        };
      },
      {amount: 0, incidentCount: 0, severity: 0},
    );
  });

  return {
    new: filteredIncidents.filter(
      (i) => DateTime.fromISO(i.created) > now.minus({days: 1}),
    ).length,
    columns: [
      {
        id: 'employee',
        label: 'Employee',
      },
      {
        id: 'amount',
        label: 'Loss',
        format: (value) => formatValue(value || undefined, 'CURRENCY'),
      },
      {
        id: 'lastIncidentTime',
        label: 'Latest Incident',
        format: (value) =>
          DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        id: 'incidentCount',
        label: '# Incidents',
      },
    ],
    rows: _.take(_.sortBy(rows, ['severity', 'incidentCount']).reverse(), 5),
  };
};

export const siteIncidentsWidget = async (filters) => {
  const filteredIncidents = await getIncidents(filters);

  const sites = _.groupBy(filteredIncidents, 'siteId');
  const rows = Object.values(sites).map((siteIncidents) => {
    return siteIncidents.reduce(
      (acc, i) => {
        let {lastIncidentTime} = acc;
        if (
          !lastIncidentTime ||
          DateTime.fromISO(lastIncidentTime) < DateTime.fromISO(i.incidentTime)
        ) {
          lastIncidentTime = i.incidentTime;
        }
        return {
          id: i.siteId,
          amount: (acc.amount ?? 0) + (i.amount ?? 0),
          siteName: i.siteName,
          lastIncidentTime,
          totalIncidents: acc.totalIncidents + 1,
          drilldown: `Location|${i.siteId}`,
        };
      },
      {amount: 0, totalIncidents: 0, severity: 0},
    );
  });

  return {
    new: filteredIncidents.filter(
      (i) => DateTime.fromISO(i.created) > now.minus({days: 1}),
    ).length,
    columns: [
      {
        id: 'siteName',
        label: 'Site',
      },
      {
        id: 'amount',
        label: 'Loss',
        format: (value) => formatValue(value || undefined, 'CURRENCY'),
      },
      {
        id: 'lastIncidentTime',
        label: 'Latest Incident',
        format: (value) =>
          DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        id: 'totalIncidents',
        label: '# Incidents',
      },
    ],
    rows: _.take(_.sortBy(rows, 'totalIncidents').reverse(), 5),
  };
};

export const getWidgetFunction = (widgetReference) => {
  return {
    'incident-age': incidentAgeWidget,
    'incident-category-breakdown': incidentCategoryBreakdownWidget,
    'employee-incidents': employeeIncidentsWidget,
    'site-incidents': siteIncidentsWidget,
  }[widgetReference];
};
