import {isNewNavigationWebEnabled} from '../../util/user';

export const getNavigationItems = (tabs, currentUser, siteId) => {
  const navigationItems = tabs.map((tab) => ({
    path: siteId ? `${tab.path}?siteId=${siteId}` : tab.path,
    name: tab.label,
    icon: tab.icon,
    primaryTitleText: tab.primaryTitleText,
    shortTitleText: tab.shortTitleText,
    displayOnSmallScreen: tab.displayOnSmallScreen ?? true,
  }));
  return isNewNavigationWebEnabled(currentUser) ? {navigationItems} : {};
};
