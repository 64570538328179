import React, {Children} from 'react';
import {Route, Switch} from 'react-router-dom';
import DashboardComponent from '../../shared/components/dashboardComponent';
import SspSection from './landingPage/SspSection';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {isMobileApp} from '../../shared/util/general';
import {isNewNavigationWebEnabled} from '../../shared/util/user';
import {getTabs} from './securityPillarRoutes';
import {RedirectWithQueryParams} from '../../shared/components/redirectWithQueryParams';
import {getNavigationItems} from '../../shared/components/getNavigationItems';
import GlobalErrorPage from '../globalErrorPage';

const SmartSitePageResponsive = ({currentUser}) => {
  const isContentPadding =
    !window.location.href.includes('/ui/ssp/alarm-configuration') ||
    !window.location.href.includes('/ui/ssp/alarm-events');

  const navigationProps = getNavigationItems(getTabs(currentUser), currentUser);

  const titleProps = !currentUser.settings.newNavigationWeb
    ? {defaultPageTitle: 'Smart Site Protection'}
    : {};
  const pageHeaderProps =
    !isMobileApp() && !currentUser.settings.newNavigationWeb
      ? {title: 'Smart Site Protection'}
      : {};

  return (
    <PageTemplateResponsive
      PageHeaderProps={pageHeaderProps}
      PageContentProps={{
        marginY: isContentPadding ? {xs: 1, sm: 3, md: 3, lg: 3, xl: 3} : 0,
        marginX: isContentPadding ? {xs: 2, sm: 2.5, md: 3, lg: 5} : 0,
      }}
      pillarName="Security"
      {...titleProps}
      {...navigationProps}
    >
      {isNewNavigationWebEnabled(currentUser) ? (
        <Switch>
          <Route exact path="/ssp">
            <RedirectWithQueryParams to="/ssp/landing-page" />
          </Route>
          {getTabs(currentUser).map((tab) => (
            <Route
              exact
              key={tab.key}
              path={`/ssp/${tab.path}`}
              render={() => React.createElement(tab.component, tab.props)}
            />
          ))}
          <Route path="*" component={GlobalErrorPage} />
        </Switch>
      ) : (
        <DashboardComponent data-cy="ssp-landing-page-content">
          {Children.toArray(<SspSection />)}
        </DashboardComponent>
      )}
    </PageTemplateResponsive>
  );
};

export default withCurrentUser(SmartSitePageResponsive);
