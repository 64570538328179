import React from 'react';
import {IconButton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CreateIcon from '@mui/icons-material/Create';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(5), // icon's height
  },
  inheritFlexBox: {
    display: 'inherit',
    alignItems: 'inherit',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.action.active,
  },
}));

const SectionTitleBar = ({
  title,
  onClick,
  icon,
  actionIcon,
  isActionIconVisible,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.inheritFlexBox} variant="h6">
        <div data-cy="title-bar-icon" className={classes.icon}>
          {icon}
        </div>
        <Typography data-cy="title-bar-title">{title}</Typography>
      </Typography>
      {onClick && isActionIconVisible && (
        <IconButton
          onClick={onClick}
          aria-label={`Edit ${title}`}
          id="edit"
          className={classes.editAction}
          data-cy="title-bar-button"
          size="large"
        >
          {actionIcon}
        </IconButton>
      )}
    </div>
  );
};

SectionTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.node.isRequired, // as a description sign of the section
  actionIcon: PropTypes.node, // as an action button
  isActionIconVisible: PropTypes.bool,
};

SectionTitleBar.defaultProps = {
  onClick: null,
  actionIcon: <CreateIcon />,
  isActionIconVisible: true,
};

export default SectionTitleBar;
