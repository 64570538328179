import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import DashboardWidget from '../../../../../shared/components/dashboardComponent/dashboardWidget';
import HighChartsWidget from '../../../../../shared/components/highcharts';
import PieChart from '../../highcharts/pieChart';

const chartComponents = {
  pie: PieChart,
};

const DashboardChartWidget = (props) => {
  const {chartType, chartOptions, id, onChildDrilldown, ...rest} = props;

  const ChartComponent = useMemo(
    () => chartComponents[chartType] ?? HighChartsWidget,
    [chartType],
  );

  const {slotProps: chartSlotProps} = chartOptions;

  const slotProps = useMemo(
    () =>
      merge(
        {
          chart: {backgroundColor: 'paper'},
          childrenSeries: {
            cursor: 'pointer',
            events: {click: onChildDrilldown},
          },
        },
        chartSlotProps,
      ),
    [chartSlotProps, onChildDrilldown],
  );

  return (
    <DashboardWidget
      id={id}
      {...rest}
      content={
        <ChartComponent
          id={`${id}-chart`}
          {...chartOptions}
          slotProps={slotProps}
        />
      }
    />
  );
};

DashboardChartWidget.propTypes = {
  id: PropTypes.string.isRequired,
  chartOptions: PropTypes.shape({
    slotProps: PropTypes.shape({}),
  }).isRequired,
  chartType: PropTypes.oneOf(Object.keys(chartComponents)).isRequired,
};

export default DashboardChartWidget;
