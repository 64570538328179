import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DateTime} from 'luxon';
import {TimeField} from '@mui/x-date-pickers/TimeField';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  timePicker: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  closeIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const isEndOfDay = (date) => {
  if (!date) return false;

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return hours === 23 && minutes === 59 && seconds === 59;
};

// needed to maintain same date, as current date used when time format 'hh:mm a'
const normalizeToSameDayDateTime = (startOfDayDateTime, newDateTime) =>
  startOfDayDateTime.plus(
    (newDateTime.hour * 3600 + newDateTime.minute * 60) * 1000,
  );

const TimeRangePicker = (props) => {
  const {onChange, onDelete, startDate, endDate, disabled, selection} = props;
  const classes = useStyles(props);

  const [errors, setErrors] = useState({});
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  useEffect(
    () => {
      setSelectedStartDate(startDate);
      setSelectedEndDate(
        isEndOfDay(endDate) ? new Date(endDate.getTime() + 1000) : endDate, // allows 12 am end of day
      );
    },
    [endDate, selection, startDate],
  );

  const startOfDayDateTime = useMemo(
    () => DateTime.fromJSDate(startDate).startOf('day'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const handleTimeError = useCallback(
    (time) => {
      const newErrors = {...errors, [time]: true};
      onChange({errors: newErrors});
      setErrors(newErrors);
    },
    [errors, onChange],
  );

  const handleUpdateStartTime = useCallback(
    (newDate) => {
      if (!newDate?.isValid) return handleTimeError('start');

      const endDateTime = DateTime.fromJSDate(endDate);
      const newStartDateTime = normalizeToSameDayDateTime(
        startOfDayDateTime,
        newDate,
      );

      if (newStartDateTime >= endDateTime) return handleTimeError('start');

      setErrors({});

      return onChange({start: newStartDateTime.toJSDate(), end: endDate});
    },
    [handleTimeError, endDate, startOfDayDateTime, onChange],
  );

  const handleUpdateEndTime = useCallback(
    (newDate) => {
      if (!newDate?.isValid) return handleTimeError('end');

      const startDateTime = DateTime.fromJSDate(startDate);
      let newEndDateTime = normalizeToSameDayDateTime(
        startOfDayDateTime,
        newDate,
      );

      // allows selecting 12 am as end of day time
      if (newEndDateTime.hour === 0 && newEndDateTime.minute === 0) {
        newEndDateTime = newEndDateTime.plus({
          hours: 23,
          minutes: 59,
          seconds: 59,
        });
      }

      if (newEndDateTime <= startDateTime) return handleTimeError('end');

      setErrors({});

      return onChange({start: startDate, end: newEndDateTime.toJSDate()});
    },
    [handleTimeError, startDate, startOfDayDateTime, onChange],
  );

  return (
    <div className={classes.root}>
      <TimeField
        name="time-picker-start"
        className={classes.timePicker}
        label="Start"
        value={DateTime.fromJSDate(selectedStartDate)}
        disabled={disabled}
        error={!!errors.start}
        onChange={handleUpdateStartTime}
      />
      <TimeField
        name="time-picker-end"
        className={classes.timePicker}
        label="End"
        value={DateTime.fromJSDate(selectedEndDate)}
        disabled={disabled}
        error={!!errors.end}
        onChange={handleUpdateEndTime}
      />
      <IconButton
        name="time-picker-delete"
        onClick={onDelete}
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default TimeRangePicker;
