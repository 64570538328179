import React, {useState} from 'react';
import {Button, Stack, Divider} from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import IncidentStatus from './IncidentStatus';
import {filtersPropType} from '../types';
import DateRange from './DateRange';

const filterComponents = {
  incidentStatus: IncidentStatus,
  dateRange: DateRange,
};

const Filters = (props) => {
  const {filterDefs, filters, onFilter} = props;
  const [internalFilters, setInternalFilters] = useState(filters);
  const [dirty, setDirty] = useState(false);

  const handleApply = () => {
    if (typeof onFilter === 'function') {
      onFilter(internalFilters);
    }
    setDirty(false);
  };

  const handleFilter = (value) => {
    setDirty(true);
    setInternalFilters((prev) => {
      const newList = [...(prev ?? [])];

      const filterIndex = newList.findIndex(
        (f) => f.paramName === value.paramName,
      );

      if (filterIndex < 0) {
        newList.push(value);
      } else {
        newList.splice(filterIndex ?? 0, 1, value);
      }
      return newList;
    });
  };

  return (
    <Stack
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
      direction="row"
    >
      <Stack
        id="dashboard-filters"
        spacing={2}
        direction="row"
        useFlexGap
        sx={{flexWrap: 'wrap', alignItems: 'center'}}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {filterDefs.map((filter) => {
          const {type, filterName, ...filterDef} = filter;
          const FilterComponent = filterComponents[type];
          if (!FilterComponent) {
            return null;
          }
          return (
            <FilterComponent
              key={kebabCase(filter.type)}
              filters={internalFilters}
              onFilter={handleFilter}
              filterName={filterName}
              {...filterDef}
            />
          );
        })}
      </Stack>
      <Button
        id="apply-filter"
        variant="contained"
        disabled={!dirty}
        onClick={handleApply}
      >
        Apply
      </Button>
    </Stack>
  );
};

Filters.propTypes = {
  filterDefs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  filters: filtersPropType,
  onFilter: PropTypes.func,
};

Filters.defaultProps = {
  onFilter: undefined,
  filters: [],
};

export default Filters;
