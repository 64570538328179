import React from 'react';
import {Grid, Box, Typography, Link} from '@mui/material';
import {kebabCase} from 'lodash';
import PropTypes from 'prop-types';

const WorkspaceMenuItem = ({item, selected, bgColor}) => (
  <Grid item xs={6}>
    <Link
      href={item.path}
      data-cy={`${kebabCase(item.title)}-button`}
      target={item.target}
      sx={{
        width: '100%',
        height: '100%',
        textDecoration: 'none',
        color: 'text.primary',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: selected ? 'primary.lightest' : 'none',
          borderRadius: 1,
          padding: 1,
          ':hover': {backgroundColor: 'action.hover'},
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: 'fit-content',
            width: 'fit-content',
            backgroundColor: bgColor,
            borderRadius: 1,
            padding: 1,
            marginBottom: 0.5,
            color: 'primary.contrastText',
          }}
        >
          {item.icon}
        </Box>

        <Typography variant="caption" sx={{lineHeight: 2}}>
          {item.title}
        </Typography>
      </Box>
    </Link>
  </Grid>
);

WorkspaceMenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.node,
    openNewTab: PropTypes.bool,
    path: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default WorkspaceMenuItem;
