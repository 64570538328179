import {
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Link,
  Typography,
  useMediaQuery,
  Chip,
  Stack,
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {PropTypes} from 'prop-types';
import logo from '../../images/envysion_logo.png';
import {
  userRoutes,
  helpCenterRoutes,
  administrationRoutes,
  platformAdminRoutes,
} from './routes';
import NavigationMenu from './navigationMenu';
import {getUserLandingPage, hasAllMenuCapabilities} from './utils';
import {useDialog} from '../../hooks';
import WorkspaceMenuDialog from './workspaceMenu';

const TopNavigationBar = (props) => {
  const {user, title, backLink} = props;
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [eventMenu, setEventMenu] = useState('');

  const [
    isWorkspaceOpen,
    handleWorkspaceOpen,
    handleWorkspaceClose,
  ] = useDialog();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const userProfileMenuRef = useRef(null);

  const handleOpenMenu = (event, eventItem) => {
    // keep valid anchor during screen resizing for user profile menu. Particularly useful for mobile use cases, such as switching between horizontal and vertical orientations
    if (eventItem === 'Profile') {
      setAnchorElMenu(userProfileMenuRef.current);
    } else {
      setAnchorElMenu(event.target);
    }
    setEventMenu(eventItem);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setEventMenu('');
  };

  const pageTitle = () => {
    const {pathname} = window.location;
    switch (true) {
      case pathname.includes('/ui/ssp'):
        return 'Security';
      case pathname.includes('/ui/reports') ||
        pathname.includes('/ui/loss-prevention'):
        return 'Loss Prevention';
      case pathname.includes('/ui/operations'):
        return 'Operations';
      case pathname.includes('/ui/home'):
        return 'Home';
      default:
        return '';
    }
  };

  useEffect(
    () => {
      if (
        !isMediumScreen &&
        (eventMenu === 'Administration' || eventMenu === 'PlatformAdmin')
      ) {
        handleCloseMenu();
      }
    },
    [eventMenu, isMediumScreen],
  );

  const navItems = [
    {
      tooltipTitle: 'Open Profile Settings',
      menuTitle: 'Profile',
      items: userRoutes(user),
      icon: (
        <Box ref={userProfileMenuRef}>
          {isMediumScreen ? (
            <Chip
              data-cy="user-profile-chip"
              variant="outlined"
              sx={{
                color: 'common.white',
                borderColor: 'common.white',
                '& span': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                },
              }}
              label={
                <Fragment>
                  <Typography variant="body2">
                    {user.displayName
                      ? user.displayName
                      : `${user.username}@${user.domainName}`}
                  </Typography>
                  <ExpandMoreIcon sx={{color: 'common.white', marginLeft: 1}} />
                </Fragment>
              }
            />
          ) : (
            <Avatar
              data-cy="user-profile-icon"
              sx={(theme) => ({
                height: theme.spacing(4),
                width: theme.spacing(4),
              })}
            >
              <Typography variant="subtitle1">
                {(user.firstName
                  ? user.firstName.charAt(0).toUpperCase()
                  : '') +
                  (user.lastName ? user.lastName.charAt(0).toUpperCase() : '')}
              </Typography>
            </Avatar>
          )}
        </Box>
      ),
      visibleOnSmallScreen: true,
    },
    {
      tooltipTitle: 'Open Help Center',
      menuTitle: 'Help',
      items: helpCenterRoutes,
      icon: <HelpOutlineIcon data-cy="help-center-icon" />,
      visibleOnSmallScreen: true,
    },
    {
      tooltipTitle: 'Open Administration Settings',
      menuTitle: 'Administration',
      items: administrationRoutes,
      icon: <SettingsOutlinedIcon data-cy="administration-settings-icon" />,
      visibleOnSmallScreen: false,
    },
    {
      tooltipTitle: 'Open Platform Admin Settings',
      menuTitle: 'PlatformAdmin',
      items: platformAdminRoutes,
      icon: <AdminPanelSettingsOutlinedIcon data-cy="platform-admin-icon" />,
      visibleOnSmallScreen: false,
    },
  ];

  return (
    <Fragment>
      <WorkspaceMenuDialog
        user={user}
        isWorkspaceOpen={isWorkspaceOpen}
        onWorkspaceClose={handleWorkspaceClose}
      />
      <AppBar
        id="top-navigation-bar"
        position="sticky"
        sx={{
          color: 'primary.dark',
          height: (theme) => theme.mixins.toolbar.height,
          display: 'flex',
          verticalAlign: 'middle',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          '& .MuiToolbar-root': {paddingX: (theme) => theme.spacing(1.75)},
        }}
        data-cy="top-navigation-bar"
      >
        <Toolbar variant="dense">
          <Stack direction="row" spacing={1} alignItems="center">
            {backLink && (
              <IconButton
                component={Link}
                href={backLink}
                size="medium"
                sx={{
                  color: 'common.white',
                }}
              >
                <ArrowBackIcon
                  fontSize="medium"
                  data-cy="back-button-new-top-bar"
                  color="common.white"
                />
              </IconButton>
            )}
            <IconButton
              onClick={handleWorkspaceOpen}
              size="medium"
              sx={{
                color: 'common.white',
                marginRight: 3,
              }}
            >
              <AppsIcon fontSize="large" data-cy="workspace-icon" />
            </IconButton>
            {isMediumScreen && (
              <Link href={getUserLandingPage(user)} display="flex">
                <Box
                  component="img"
                  sx={{
                    height: (theme) => theme.spacing(4),
                    paddingTop: 0.5,
                  }}
                  src={logo}
                  data-cy="envysion-logo"
                />
              </Link>
            )}
            <Typography
              variant="h6"
              sx={{
                paddingLeft: isMediumScreen ? 5 : 0,
                color: 'primary.contrastText',
              }}
              data-cy="pillar-title"
            >
              {title || pageTitle()}
            </Typography>
          </Stack>
          <Box sx={{flexGrow: 1}} />
          {navItems.map(
            (navItem) =>
              (navItem.visibleOnSmallScreen || isMediumScreen) &&
              hasAllMenuCapabilities(user, navItem.items) && (
                <Box key={navItem.menuTitle}>
                  <Tooltip title={navItem.tooltipTitle}>
                    <IconButton
                      size="medium"
                      sx={{color: 'common.white'}}
                      onClick={(event) =>
                        handleOpenMenu(event, navItem.menuTitle)
                      }
                    >
                      {navItem.icon}
                    </IconButton>
                  </Tooltip>
                  <NavigationMenu
                    items={navItem.items}
                    menuItem={navItem.menuTitle}
                    anchorElMenu={anchorElMenu}
                    handleCloseMenu={handleCloseMenu}
                    user={user}
                    eventMenu={eventMenu}
                  />
                </Box>
              ),
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

TopNavigationBar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    capabilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    settings: PropTypes.shape({}),
  }).isRequired,
  title: PropTypes.string,
  backLink: PropTypes.string,
};

TopNavigationBar.defaultProps = {
  title: '',
  backLink: '',
};
export default TopNavigationBar;
