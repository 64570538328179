import React from 'react';
import PropTypes from 'prop-types';
import {RadioGroup, FormControlLabel, Radio, FormControl} from '@mui/material';
import {filtersPropType} from '../types';

const IncidentStatus = (props) => {
  const {onFilter, filters, filterName, options, operatorType, ...rest} = props;

  const filterValue = filters?.find((f) => f.paramName === filterName)?.value;

  const handleChange = (value) => {
    onFilter({paramName: filterName, operatorType, value});
  };

  const controlProps = (value, label) => ({
    value,
    control: <Radio size="small" />,
    label,
    onChange: (evt) => {
      handleChange(evt.target.value);
    },
    slotProps: {
      typography: {
        variant: 'subtitle2',
      },
    },
  });

  return (
    <FormControl {...rest}>
      <RadioGroup value={filterValue}>
        {options.map(({value, label}) => (
          <FormControlLabel key={value} {...controlProps(value, label)} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

IncidentStatus.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filters: filtersPropType,
  filterName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  operatorType: PropTypes.string.isRequired,
};

IncidentStatus.defaultProps = {
  filters: [],
};

export default IncidentStatus;
