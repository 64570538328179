import React from 'react';
import {Typography, useTheme} from '@mui/material';
import DashboardTableWidget from '../../../shared/components/dashboardComponent/dashboardTableWidget';
import {filteringPropType, widgetConfigPropType} from '../types';
import {useDrilldown, useWidgetApi, useWidgetProps} from './hooks';

const IncidentTableWidget = (props) => {
  const {widgetConfig, filtering} = props;
  const [results, loading, error] = useWidgetApi(
    widgetConfig.widgetReference,
    filtering?.filters,
  );

  const theme = useTheme();

  const columns = results?.columns || [];
  const rows = results?.rows || [];

  const itemName = results?.new > 1 ? 'incidents' : 'incident';

  const {actions, widgetDrilldownActions, widgetProps} = useWidgetProps(
    widgetConfig,
  );

  const [handleDrilldown] = useDrilldown(
    widgetDrilldownActions,
    filtering?.filters,
  );

  return (
    <DashboardTableWidget
      title={widgetConfig.title}
      loading={loading}
      columns={columns}
      rows={rows}
      error={error}
      actions={actions}
      onRowClick={(evt, row) => handleDrilldown(row)}
      {...widgetProps}
      heading={
        results?.new > 0 && (
          <Typography variant="caption" gutterBottom>
            You have{' '}
            <Typography
              variant="subtitle1"
              sx={{fontWeight: theme.typography.fontWeightBold}}
              component="span"
            >
              {results.new}
            </Typography>{' '}
            new {itemName} in the last 24 hours.
          </Typography>
        )
      }
    />
  );
};

IncidentTableWidget.propTypes = {
  widgetConfig: widgetConfigPropType.isRequired,
  filtering: filteringPropType.isRequired,
};

export default IncidentTableWidget;
