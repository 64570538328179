import React from 'react';
import PropTypes from 'prop-types';
import {Popper, List, Paper, Box, styled} from '@mui/material';
import SecondNavSubListItem from './SecondNavSubListItem';

const StyledBox = styled(Box)(({handlingmorebutton}) => ({
  display: 'flex',
  justifyContent: 'center',
  ...(handlingmorebutton === 'true' && {flexDirection: 'column'}),
}));

const SecondNavSubBottomList = ({
  subNavigationState,
  onSubNavItemClick,
  onSubNavClose,
  customIsActiveMatcher,
}) => {
  const handlingMoreButton =
    subNavigationState.hoveredMainItem === 'more-button';

  const subNavigation = subNavigationState.subNavItems?.map((item) => (
    <SecondNavSubListItem
      key={`sub-nav-link-${item.path}`}
      to={item.path}
      icon={handlingMoreButton && item.icon}
      primaryTitleText={item.primaryTitleText}
      shortTitleText={item.shortTitleText}
      onClick={() => {
        onSubNavItemClick(item.primaryTitleText, item.name);
        if (handlingMoreButton) {
          onSubNavClose();
        }
      }}
      bottom
      customIsActiveMatcher={customIsActiveMatcher}
      handlingMoreButton={handlingMoreButton}
    />
  ));

  return (
    <StyledBox>
      {subNavigationState.anchorEl?.current && (
        <Popper
          placement={handlingMoreButton ? 'top-end' : 'top'}
          sx={{
            width: '100%',
            maxWidth: 'fit-content',
          }}
          open={subNavigationState.open}
          anchorEl={() => subNavigationState.anchorEl.current}
        >
          <Paper
            sx={{
              borderRadius: 2,
              m: 1,
            }}
            data-cy="bottom-sub-navigation"
          >
            <List
              sx={{
                p: 0,
              }}
            >
              <StyledBox handlingmorebutton={handlingMoreButton.toString()}>
                {subNavigation}
              </StyledBox>
            </List>
          </Paper>
        </Popper>
      )}
    </StyledBox>
  );
};

SecondNavSubBottomList.propTypes = {
  /**
   * State of bottom sub navigation item on smaller screens (720px and less)
   * This is only for bottom navigation elements
   */
  subNavigationState: PropTypes.shape({
    /**
     * Boolean for indicating if item is active
     */
    open: PropTypes.bool,
    /**
     * Array of sub navigation items (objects) to display in the pop up menu
     * Objects should contain:
     *
     * path - String containing path directing where to navigate on click
     *   Should contain path element which needs to be added to parent path
     *   Switch component in the same instance of pageTemplateResponsive should handle the same route
     *   Props is passed to NavLink component 'to' property
     *
     * capabilities - Array of strings defining capabilities
     *   needed to show item in nav bar
     *
     * id - unique identifier
     *
     * name - identifying name for items. It's required that all subItems
     *   should have the same value as parent item
     *
     * icon - Node with icon to display in sub nav menu item
     *
     * primaryTitleText - String containing display name of sub nav menu item
     *   primary title is used on bottom navigation item when no shortTitleText is provided
     *
     * shortTitleText - String containing display name of sub nav menu item
     *   short title is used on bottom item if provided
     */
    subNavItems: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        capabilities: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.node,
        primaryTitleText: PropTypes.string,
        shortTitleText: PropTypes.string,
      }),
    ),
  }).isRequired,
  /**
   *   Callback function to handle clicks on sub-nav-items
   */
  onSubNavItemClick: PropTypes.func.isRequired,
  /**
   *  Callback function handle closing sub-nav-items
   */
  onSubNavClose: PropTypes.func.isRequired,
  /**
   * Callback function handle for custom path match to highlight active item
   */
  customIsActiveMatcher: PropTypes.func,
};

SecondNavSubBottomList.defaultProps = {
  customIsActiveMatcher: null,
};
export default SecondNavSubBottomList;
