import axios from 'axios';
import {convertIdsToString} from './util';

import {sortByNullableField} from '../shared/util/sorting';

export const getUser = async (userId = 'me') =>
  (await axios.get(`/users/${userId}`)).data;

export const getFullUserById = async (userId = 'me') =>
  (await axios.get(`/users/${userId}/extensive`)).data;

export const menu = async (userId = 'me') => {
  const {data} = await axios.get(`/users/${userId}/menu`);
  return data;
};

export const updatePreferences = async (userId, preferences) => {
  const instance = axios.create();
  instance.interceptors.request.eject(
    instance.interceptorIds?.requestCasingInterceptor,
  );
  instance.interceptors.response.eject(
    instance.interceptorIds?.responseCasingInterceptor,
  );

  await instance.put(`/users/${userId}/preferences`, preferences);
};

export const patchUser = async (userId, set, unset) =>
  (await axios.patch(`/users/${userId}`, {set, unset})).data;

export const changePassword = async (newPassword, currentPassword) =>
  axios.put('/users/me/password', {newPassword, currentPassword});

export const createUser = async (formData) =>
  (await axios.post(`/users?send_welcome_email=true`, formData)).data;

export const changeEmail = async (userId, email) =>
  axios.put(`/users/${userId}/email`, {email});

// Old query, to be removed
export const getUserList = async (domainId, username) => {
  const query = [];
  if (domainId) {
    query.push(`domain_id=${domainId}`);
  }
  if (username) {
    query.push(`username=${username}`);
  }

  return sortByNullableField(
    (await axios.get(query.length ? `/users?${query.join('&')}` : '/users'))
      .data,
    'username',
  );
};

// get a batch of users and handle pagination params
export const getUserListPage = async (
  domainId,
  username,
  limit = 1000,
  offset = 0,
) => {
  const query = [];
  if (domainId) {
    query.push(`domain_id=${domainId}`);
  }
  if (username) {
    query.push(`username=${username}`);
  }
  if (limit) {
    query.push(`limit=${limit}`);
  }
  if (offset) {
    query.push(`offset=${offset}`);
  }

  const {data} = await axios.get(
    query.length ? `/users-page?${query.join('&')}` : '/users-page',
  );

  data.results = sortByNullableField(data.results, 'username');
  return data.results;
};

// get every single user recursively
export const getAllUsers = async (
  domainId,
  username,
  limit = 1000,
  offset = 0,
  returned = {count: Infinity, results: []},
) => {
  if (
    returned?.results?.length >= returned?.count ||
    offset >= returned.count
  ) {
    return returned.results;
  }
  const query = [];
  if (domainId) {
    query.push(`domain_id=${domainId}`);
  }
  if (username) {
    query.push(`username=${username}`);
  }

  if (limit) {
    query.push(`limit=${limit}`);
  }
  if (offset) {
    query.push(`offset=${offset}`);
  }

  const {data} = await axios.get(
    query.length ? `/users-page?${query.join('&')}` : '/users-page',
  );

  data.results = sortByNullableField(data.results, 'username');
  return getAllUsers(domainId, username, limit, offset + limit, {
    count: data.count,
    results: returned.results.concat(data.results),
  });
};

export const personaList = async () => {
  const {data} = await axios.get('/personas');

  return convertIdsToString(data);
};

export const resetUserPassword = async (userId) =>
  (await axios.post(`/users/${userId}/reset_password`)).data;

export const disableUser = async (userId) =>
  (await axios.delete(`/users/${userId}`)).data;

export const deleteUser = async (userId) =>
  axios.delete(`/users/${userId}`, {params: {force: true}});

export const enableUser = async (userId) =>
  (await axios.post(`/users/${userId}/enable`)).data;

export const migrateClips = async (sourceUserId, destinationUserId) =>
  (await axios.post('/clips2/bulk/migrate_owner', {
    sourceUserId,
    destinationUserId,
  })).data;

export const getBiAlerts = async (userId) => {
  return sortByNullableField(
    (await axios.get(`/users/${userId}/bi_alerts`)).data,
    'name',
  );
};

export const updateBiAlert = (userId, userReportId, settings) =>
  axios.put(`/users/${userId}/bi_alerts/${userReportId}`, settings);

export const getAuditNotifications = async (userId) => {
  return sortByNullableField(
    (await axios.get(`/users/${userId}/notification_settings`)).data,
    'name',
  );
};

export const updateAuditNotifications = (userId, notificationType, settings) =>
  axios.put(
    `/users/${userId}/notification_settings/${notificationType}`,
    settings,
  );

export const getDomainUsageSummary = async () =>
  (await axios.get('/users/reports/domainUsageSummary')).data;

export const getDomainUsageDailySummary = async (date) => {
  const year = date.toString().slice(0, 4);
  const month = date.toString().slice(4, 6);

  return (await axios.get(
    `/users/reports/domainUsageDailySummary?year=${year}&month=${month}`,
  )).data;
};

export const getUsageByUser = async (date) => {
  const year = date.toString().slice(0, 4);
  const month = date.toString().slice(4, 6);

  return sortByNullableField(
    (await axios.get(
      `/users/reports/usage?month=${month}&year=${year}&download=false`,
    )).data,
    'userName',
  );
};
