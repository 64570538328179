import React from 'react';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {filteringPropType, widgetConfigPropType} from '../types';
import Filters from '../filters';
import {useWidgetProps} from './hooks';

const SectionHeaderWidget = (props) => {
  const {widgetConfig, filtering} = props;

  const {widgetFilterDefs, widgetProps} = useWidgetProps(widgetConfig);

  return (
    <DashboardSection
      id={`section-header-${kebabCase(widgetConfig.name)}`}
      title={widgetConfig.title}
      subtitle={widgetConfig.description}
      {...widgetProps}
      filters={
        !isEmpty(widgetFilterDefs) && (
          <Filters
            filterDefs={widgetFilterDefs}
            filters={filtering?.filters}
            onFilter={filtering?.setFilters}
          />
        )
      }
    />
  );
};

SectionHeaderWidget.propTypes = {
  widgetConfig: widgetConfigPropType.isRequired,
  filtering: filteringPropType.isRequired,
};

export default SectionHeaderWidget;
