import React, {useState, useMemo} from 'react';
import merge from 'lodash/merge';
import {Box, FormControlLabel, Switch, useTheme} from '@mui/material';
import {alpha} from '@mui/material/styles';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import HighCharts from 'highcharts';
import BaseChart from '../BaseChart';

const {colors: defaultColors} = HighCharts.getOptions();

const theftColor = '#248DC1';
const lossColor = '#69B36C';
const operationsColor = '#FFDA6A';
const securityColor = '#A22E0A';
const safetyColor = '#B32473';
const alphaRanges = [1, 0.7, 0.5, 0.3];

const applyAlphas = (color) => alphaRanges.map((a) => alpha(color, a));

// TODO: move this elsewhere
// TODO: change from category names to theme names
const colorVariants = {
  theft: {
    graphColors: applyAlphas(theftColor),
  },
  loss: {
    graphColors: applyAlphas(lossColor),
  },
  operations: {
    graphColors: applyAlphas(operationsColor),
  },
  security: {
    graphColors: applyAlphas(securityColor),
  },
  safety: {
    graphColors: applyAlphas(safetyColor),
  },
};

const defaultSizes = {
  inner: {
    max: '45%',
  },
  outer: {
    min: '60%',
    max: '80%',
  },
};

// TODO: figure out how to encapsulate the color selection better. Maybe move the category to palette mapping into slot props somewhere
const getSliceColor = (slice, categoryIndex, overallIndex) => {
  const defaultColor = defaultColors[overallIndex % defaultColors.length];
  const categoryColors = colorVariants[(slice.custom?.type)]?.graphColors;
  if (categoryColors) {
    const categoryColor = categoryColors[categoryIndex % categoryColors.length];
    return categoryColor;
  }
  return defaultColor;
};

const formatDrilldownData = (data, slotProps) => {
  const {parentsSeries, childrenSeries} = slotProps;

  const parentData = [];
  const drilldownSeries = [];

  if (isEmpty(data)) {
    return {
      series: [],
    };
  }

  let categoryIndex;

  data.forEach((parent, idx) => {
    if (
      idx === 0 ||
      (idx > 0 && data[idx - 1].custom?.type !== parent.custom?.type)
    ) {
      categoryIndex = 0;
    } else {
      categoryIndex += 1;
    }
    const color = getSliceColor(parent, categoryIndex, idx);
    const drilldownId = kebabCase(parent.name);
    parentData.push(
      merge({color, drilldown: drilldownId}, omit(parent, 'children')),
    );

    const drilldownData = parent.children.map((child, childIdx) => {
      return merge({color: getSliceColor(child, childIdx)}, child);
    });
    drilldownSeries.push(
      merge(
        {
          id: drilldownId,
          data: drilldownData,
          name: 'Children',
          size: defaultSizes.outer.max,
          innerSize: defaultSizes.inner.min,
        },
        childrenSeries,
      ),
    );
  });

  return {
    series: [
      merge(
        {
          name: 'Parents',
          data: parentData,
          size: defaultSizes.outer.max,
          innerSize: defaultSizes.inner.min,
          id: 'parents',
        },
        parentsSeries,
      ),
    ],
    drilldown: {
      series: drilldownSeries,
    },
  };
};

const formatLayeredData = (data, slotProps, showChildren, theme) => {
  const {parentsSeries, childrenSeries} = slotProps;

  if (isEmpty(data)) {
    return {
      series: [],
    };
  }

  const parentData = [];
  const childrenData = [];

  let categoryIndex = 0;

  data.forEach((parent, idx) => {
    if (
      idx === 0 ||
      (idx > 0 && data[idx - 1].custom?.category !== parent.custom?.category)
    ) {
      categoryIndex = 0;
    } else {
      categoryIndex += 1;
    }
    const color = getSliceColor(parent, categoryIndex, idx);
    parentData.push(merge({color}, omit(parent, 'children')));

    parent.children.forEach((child) => {
      childrenData.push(merge({color}, child));
    });
  });

  const parents = merge(
    {
      name: 'Parents',
      data: parentData,
      size: defaultSizes.inner.max,
      innerSize: defaultSizes.inner.min,
      id: 'parents',
      point: {
        events: {
          legendItemClick: () => false,
        },
      },
    },
    parentsSeries,
  );

  const children = merge(
    {
      name: 'Children',
      data: childrenData,
      size: defaultSizes.outer.max,
      innerSize: defaultSizes.outer.min,
      id: 'children',
      dataLabels: {
        style: {
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
    },
    childrenSeries,
  );

  const retVal = [parents];

  if (showChildren) {
    retVal.push(children);
    merge(retVal, [{showInLegend: true, dataLabels: {enabled: false}}]);
  } else {
    merge(retVal, [
      {
        size: defaultSizes.outer.max,
        innerSize: defaultSizes.inner.min,
        showInLegend: false,
        dataLabels: {enabled: true},
      },
    ]);
  }

  return {series: retVal};
};

const PieChart = (props) => {
  const {data, slotProps, defaultShowChildren, variant, ...rest} = props;
  const [showChildren, setShowChildren] = useState(defaultShowChildren);

  const theme = useTheme();

  const chartData = useMemo(
    () => {
      if (variant === 'layered') {
        return formatLayeredData(data, slotProps, showChildren, theme);
      }
      return formatDrilldownData(data, slotProps);
    },
    [data, showChildren, slotProps, theme, variant],
  );

  const chartOptions = merge(
    // Chart specific
    {
      chart: {
        type: 'pie',
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          events: {
            checkboxClick: () => false,
          },
        },
      },
      legend: {
        events: {
          itemClick: () => false,
        },
        itemStyle: {
          cursor: 'default',
        },
      },
    },
    // Escape hatch for overrides
    slotProps.chart ?? {},
  );

  slotProps.chart = chartOptions;

  const ChartComponent = (
    <BaseChart {...rest} data={chartData} slotProps={slotProps} />
  );

  if (variant === 'layered') {
    return (
      <Box>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={defaultShowChildren}
              onChange={(evt) => setShowChildren(evt.target.checked)}
            />
          }
          label={slotProps.showChildren?.label ?? 'Show children'}
        />
        {ChartComponent}
      </Box>
    );
  }

  return ChartComponent;
};

PieChart.propTypes = {
  slotProps: PropTypes.shape({
    parentsSeries: PropTypes.shape({
      name: PropTypes.string,
      style: PropTypes.shape({}),
    }),
    childrenSeries: PropTypes.shape({
      name: PropTypes.string,
      style: PropTypes.shape({}),
    }),
    showChildren: PropTypes.shape({
      label: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    chart: PropTypes.object,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          y: PropTypes.number.isRequired,
        }),
      ),
    }),
  ),
  defaultShowChildren: PropTypes.bool,
  variant: PropTypes.oneOf(['layered', 'drilldown']),
};

PieChart.defaultProps = {
  defaultShowChildren: false,
  data: undefined,
  variant: 'layered',
  slotProps: {},
};

export default PieChart;
