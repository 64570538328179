import React, {useMemo, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';
import kebabCase from 'lodash/kebabCase';

import DashboardTableWidget from '../dashboardTableWidget';

const typographyVariants = {
  header: 'h6',
  primaryValue: 'h4',
  secondaryValue: 'subtitle1',
};

const ValueCell = (props) => {
  const {value, slotProps} = props;
  const {primaryValue, secondaryValue} = slotProps;
  return typeof value === 'object' ? (
    <Fragment>
      <Typography
        variant={typographyVariants.primaryValue}
        {...primaryValue ?? {}}
      >
        {value.primary}
      </Typography>
      <Typography
        variant={typographyVariants.secondaryValue}
        {...secondaryValue ?? {}}
      >
        {value.secondary}
      </Typography>
    </Fragment>
  ) : (
    <Typography
      variant={primaryValue?.variant ?? typographyVariants.primaryValue}
      {...primaryValue ?? {}}
    >
      {value ?? '-'}
    </Typography>
  );
};

ValueCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({
      primary: PropTypes.node.isRequired,
      secondary: PropTypes.node.isRequired,
    }),
  ]),
  slotProps: PropTypes.shape({
    primaryValue: PropTypes.shape({
      variant: PropTypes.string,
    }),
    secondaryValue: PropTypes.shape({
      variant: PropTypes.string,
    }),
  }),
};

ValueCell.defaultProps = {
  slotProps: {},
  value: undefined,
};

const generateColumns = (ages, slotProps) => {
  const {header} = slotProps;
  const sortedAges = ages.sort((a, b) => a - b);

  return [
    {
      id: 'label',
      label: '',
      cellProps: {
        variant: 'head',
        scope: 'row',
      },
      format: (v) => (
        <Typography variant={typographyVariants.header} {...header ?? {}}>
          {v}
        </Typography>
      ),
    },
    ...sortedAges.map((a) => ({
      id: a,
      label: `${a} days`,
      headerProps: {
        align: 'center',
      },
      headerFormat: (c) => (
        <Typography variant={typographyVariants.header} {...header ?? {}}>
          {c.label}
        </Typography>
      ),
      cellProps: {
        align: 'center',
      },
      format: (v) => <ValueCell value={v} slotProps={slotProps} />,
    })),
  ];
};

const DashboardAgeProgressionWidget = (props) => {
  const {ages, rows, slotProps, ...rest} = props;

  const tableColumns = useMemo(() => generateColumns(ages, slotProps), [
    ages,
    slotProps,
  ]);

  const tableRows = rows.map((r) => ({
    id: kebabCase(r.label),
    label: r.label,
    ...r.values,
  }));

  return (
    <DashboardTableWidget
      {...rest}
      columns={tableColumns}
      rows={tableRows}
      slotProps={{
        table: {
          size: 'medium',
        },
      }}
    />
  );
};

DashboardAgeProgressionWidget.propTypes = {
  ages: PropTypes.arrayOf(PropTypes.number).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.objectOf(ValueCell.propTypes.value).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  slotProps: PropTypes.shape({
    header: PropTypes.shape({
      variant: PropTypes.string,
    }),
    primaryValue: PropTypes.shape({
      variant: PropTypes.string,
    }),
    secondaryValue: PropTypes.shape({
      variant: PropTypes.string,
    }),
  }),
};

DashboardAgeProgressionWidget.defaultProps = {
  slotProps: {},
};

export default DashboardAgeProgressionWidget;
