/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import omit from 'lodash/omit';

import {wrapDisplayName} from '../../util/hocSupport';

// Copied from: https://gist.github.com/jamsesso/7aa94a2a7ccaa33968b6eaca974d4c21
function withUrlState(initialState = {}) {
  return (C) =>
    withRouter(
      class WithUrlState extends Component {
        // Callbacks to invoke when the URL changes.
        listeners = [];

        /* eslint-disable-next-line react/static-property-placement */
        static displayName = wrapDisplayName('WithUrlState', C);

        componentDidUpdate(prevProps) {
          const {
            location: {search},
          } = this.props;

          if (prevProps.location.search !== search) {
            this.listeners.forEach((f) => f(this.getUrlState()));
          }
        }

        handleSetUrlState = (
          nextUrlState,
          method = 'replace',
          silentUpdate = false,
        ) => {
          const {history, location} = this.props;
          const currentUrlState = queryString.parse(location.search);

          let nextQueryString = {
            ...currentUrlState,
            ...(typeof nextUrlState === 'function'
              ? nextUrlState(currentUrlState)
              : nextUrlState),
          };

          const emptyQueryParams = this.arrayOfEmptyParams(nextQueryString);
          if (emptyQueryParams.length > 0) {
            nextQueryString = omit(nextQueryString, emptyQueryParams);
          }
          const newUrl = `?${queryString.stringify(nextQueryString)}`;
          if (silentUpdate) {
            window.history.replaceState({}, '', newUrl);
          } else {
            history[method](newUrl);
          }
        };

        arrayOfEmptyParams = (state) => {
          const emptyParamArray = [];
          Object.entries(state).forEach(([key, val]) => {
            if (typeof val === 'undefined' || val === '') {
              emptyParamArray.push(key);
            }
          });
          return emptyParamArray;
        };

        getUrlState = () => {
          const {location} = this.props;

          return {
            ...initialState,
            ...queryString.parse(location.search),
          };
        };

        handleUrlStateChange = (f) => {
          this.listeners.push(f);
        };

        render() {
          return (
            <C
              {...this.props}
              urlState={this.getUrlState()}
              setUrlState={this.handleSetUrlState}
              onUrlStateChange={this.handleUrlStateChange}
            />
          );
        }
      },
    );
}

export default withUrlState;
