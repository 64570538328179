import React from 'react';
import {ToggleButton, ToggleButtonGroup, styled} from '@mui/material';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';
import {
  dateRange,
  getFilterDateRange,
} from '../../../../pages/smartSiteProtection/landingPage/dateRangeUtils';
import {filtersPropType} from '../types';

const ranges = [
  dateRange.last90Days,
  dateRange.last30Days,
  dateRange.last14Days,
  dateRange.last7Days,
  dateRange.last24Hours,
];

const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
  },
}));

const DateRange = (props) => {
  const {onFilter, filters, filterName, ...rest} = props;

  const handleChange = (evt, duration) => {
    if (duration == null) {
      onFilter({paramName: filterName, value: null});
    } else {
      const range = getFilterDateRange({days: duration});

      onFilter({
        paramName: filterName,
        operatorType: 'BETWEEN',
        value: `${range.startDate.toISODate()}:${range.endDate.toISODate()}`,
      });
    }
  };

  const filterRange = filters
    ?.find((f) => f.paramName === filterName)
    ?.value.split(':');

  let value;
  if (filterRange) {
    const min = DateTime.fromISO(filterRange[0]);
    const max = DateTime.fromISO(filterRange[1]);
    value = Math.round(max.diff(min).as('days'));
  }

  return (
    <ToggleButtonGroup
      color="primary"
      // TODO: pass id in as prop
      id="incident-offset-filter"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="date-range-filter"
      {...rest}
    >
      {ranges.map((range) => (
        <StyledToggleButton key={`day_${range.days}`} value={range.days}>
          {range.displayText}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

DateRange.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filters: filtersPropType,
  filterName: PropTypes.string.isRequired,
};

DateRange.defaultProps = {
  filters: [],
};

export default DateRange;
