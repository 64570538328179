import React, {useContext, useEffect} from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import compose from 'lodash/flowRight';

import GlobalErrorPage from '../../globalErrorPage';
import {withCurrentUser} from '../../../shared/contexts/currentUserContext';
import {isNewNavigationWebEnabled} from '../../../shared/util/user';
import PageTemplateResponsive from '../../../shared/components/pageTemplateResponsive';
import {getNavigationItems} from '../../../shared/components/getNavigationItems';
import {getTabs} from './siteSettingsRoutes';
import withUrlState from '../../../shared/components/withUrlState';
import {TopNavigationContext} from '../../../shared/components/navigationBar/topNavigationContext';

const customIsActiveMatcher = (value, pathname) => {
  const cleanPathname = pathname.split('?')[0];
  const cleanValue = value.split('?')[0];
  return cleanPathname === cleanValue;
};

const SiteSettings = ({currentUser, urlState}) => {
  const {siteId} = urlState;
  const {setTitle, setBackLink} = useContext(TopNavigationContext);
  useEffect(
    () => {
      setTitle('Site Settings');
      if (siteId) setBackLink(`/ui/home/sites/${siteId}`);
      return () => {
        setTitle('');
        setBackLink('');
      };
    },
    [setBackLink, setTitle, siteId],
  );
  if (isNewNavigationWebEnabled(currentUser)) {
    const tabs = getTabs(currentUser);
    const navigationProps = getNavigationItems(tabs, currentUser, siteId);

    if (tabs.length === 0) return <GlobalErrorPage />;

    return (
      <PageTemplateResponsive
        PageContentProps={{marginX: 0}}
        customIsActiveMatcher={customIsActiveMatcher}
        pillarName="Home"
        {...navigationProps}
      >
        <Route exact path="/home/site-settings">
          <Redirect
            to={`/home/site-settings/devices${
              siteId ? `?siteId=${siteId}` : ''
            }`}
          />
        </Route>
        <Switch>
          {tabs.map((tab) => (
            <Route
              exact
              key={tab.key}
              path={`/home/site-settings/${tab.path}`}
              component={tab.component}
            />
          ))}
        </Switch>
      </PageTemplateResponsive>
    );
  }
  return <GlobalErrorPage />;
};

export default compose(
  withRouter,
  withCurrentUser,
  withUrlState(),
)(SiteSettings);
