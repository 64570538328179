import React, {PureComponent} from 'react';
import {TextField, InputAdornment, IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import _ from 'lodash';
import Spinner from '../spinner';

class FilterField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: props.filterValue,
    };
    if (props.debounce) {
      this.debounceFilterDataset = debounce(
        (val) => this.filterDataSet(val),
        props.dataSet && props.dataSet.length > 1000 ? 700 : 200,
      );
    }
  }

  componentDidMount() {
    const {filterValue} = this.props;
    if (this.props.dataSet && !isEmpty(filterValue)) {
      this.filterDataSet(filterValue);
    }
  }

  async componentDidUpdate(prevProps) {
    const {onFilter, dataSet, deepCompareOnUpdate} = this.props;

    // If we want a deep compare, use lodash for deep comparison, otherwise
    // just use length, which should be sufficient for most use cases
    const shouldFireFilter = deepCompareOnUpdate
      ? !isEqual(prevProps.dataSet, dataSet)
      : dataSet &&
        prevProps.dataSet &&
        prevProps.dataSet.length !== dataSet.length;

    // Fire filter logic if the dataSets length has changed.
    if (shouldFireFilter) {
      if (onFilter) {
        await onFilter(this.state.filterValue);
      }
      this.filterDataSet(this.state.filterValue);
    }
  }

  formatItem = (item) => {
    const {fields} = this.props;
    const fieldsToFormat =
      fields && fields.length && typeof fields[0] !== 'string'
        ? fields.filter((fieldDef) => fieldDef.format)
        : [];
    if (
      !fieldsToFormat.length ||
      !item ||
      Array.isArray(item) ||
      typeof item !== 'object'
    ) {
      return item;
    }
    // set fields in DEEP CLONED formattedItem to their formatted values
    const formattedItem = _.cloneDeep(item);
    fieldsToFormat.map((fieldDef) =>
      _.set(
        formattedItem,
        fieldDef.id,
        fieldDef.format(_.get(item, fieldDef.id)),
      ),
    );
    return formattedItem;
  };

  filterDataSet = (filterValue) => {
    const {dataSet, onDataSetUpdate, fields} = this.props;
    if (dataSet && onDataSetUpdate) {
      const filteredData =
        filterValue && filterValue.length && dataSet
          ? dataSet.filter((item) => {
              if (item === null) {
                return false;
              }
              let filterSubject = this.formatItem(item);
              if (fields && fields.length) {
                const fieldNames =
                  typeof fields[0] === 'string'
                    ? fields
                    : fields.map((f) => f.id);
                // _.get handles nested object dot notation
                filterSubject = fieldNames
                  .map((fieldName) => _.get(filterSubject, fieldName))
                  .filter((val) => typeof val !== 'undefined');
              } else if (
                !Array.isArray(filterSubject) &&
                typeof filterSubject === 'object'
              ) {
                filterSubject = Object.values(filterSubject)
                  .map((subject) => JSON.stringify(subject))
                  .filter((f) => typeof f !== 'undefined');
              } else {
                return filterSubject
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              }
              try {
                return filterSubject.some(
                  (subjectDataItemValue) =>
                    subjectDataItemValue !== null &&
                    subjectDataItemValue
                      .toString()
                      .toLowerCase()
                      .includes(filterValue.toString().toLowerCase()),
                );
              } catch (e) {
                // ignore exceptions if data type isn't matched, assumes shallow dataset
                return true;
              }
            })
          : dataSet;

      onDataSetUpdate(filteredData, filterValue);
    }
  };

  handleSearchClick = () => {
    this.forceUpdate();
    if (this.props.onSearchClick) this.props.onSearchClick();
  };

  handleChange = (e) => {
    const {value: filterValue} = e.target;
    const {onFilter} = this.props;
    this.setState({filterValue});
    if (onFilter) {
      onFilter(filterValue);
    }
    if (this.debounceFilterDataset) {
      return this.debounceFilterDataset(filterValue);
    }
    return this.filterDataSet(filterValue);
  };

  render() {
    const {
      filterValue, // get the filterValue out of props sent to the TextField
      InputButtonProps,
      onSearchClick,
      isLoading,
      autofocus,
      ...other
    } = this.props;
    return (
      <TextField
        // TODO: move this to a TextFieldProps prop value
        {...omit(
          other,
          'debounce',
          'dataSet',
          'deepCompareOnUpdate',
          'onDataSetUpdate',
          'onFilter',
          'tabIndex',
        )}
        name="filterField"
        value={this.state.filterValue}
        onChange={this.handleChange}
        InputLabelProps={{style: {top: 'unset'}}}
        InputProps={{
          endAdornment: isLoading ? (
            <InputAdornment position="end">
              <Spinner size={15} color="primary" />
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="Search"
                onClick={this.handleSearchClick}
                {...InputButtonProps}
                size="large"
                color="default"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        autoFocus={autofocus}
      />
    );
  }
}

// alternatively to supplying dataSet and onDataSetUpdate you can use just
// onFilter to create a custom filter in the parent component, in which case
// you do not need to supply either dataSet or onDataSetUpdate
// NOTE: if debounce is true, onFilter should be used to update the parents
// filter value as
FilterField.propTypes = {
  label: PropTypes.string,
  margin: PropTypes.string,
  onFilter: PropTypes.func,
  debounce: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataSet: PropTypes.arrayOf(PropTypes.shape({})),
  onDataSetUpdate: PropTypes.func,
  onSearchClick: PropTypes.func,
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        format: PropTypes.func, // format is used to convert the field during search
      }),
    ),
  ]),
  filterValue: PropTypes.string,
  deepCompareOnUpdate: PropTypes.bool,
  /* eslint-disable-next-line react/forbid-prop-types */
  InputButtonProps: PropTypes.object,
  autofocus: PropTypes.bool,
};

FilterField.defaultProps = {
  label: 'Search',
  margin: 'normal',
  onFilter: () => {},
  debounce: false,
  isLoading: false,
  dataSet: null,
  onDataSetUpdate: () => {},
  onSearchClick: () => {},
  fields: null,
  deepCompareOnUpdate: false,
  filterValue: '',
  InputButtonProps: {},
  autofocus: false,
};

export default FilterField;
