import PropTypes from 'prop-types';

export const widgetConfigPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.object,
});

export const filtersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    paramName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
);

export const filteringPropType = PropTypes.shape({
  filters: filtersPropType.isRequired,
  setFilter: PropTypes.func,
});
