import 'videojs-panorama/dist/videojs-panorama.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import './setupAxios';
import Routes from './routes';
import ThemeProvider from './ThemeProvider';
import './setupDebug';
import './setupGA';
import './setupYup';
import './setupHighcharts';
import {LoggerContext} from './shared/components/logger';
import {FirebaseNotificationsContext} from './shared/components/firebase';
import LogMaster from './shared/components/logger/logMaster';
import {SentryLogger} from './shared/components/logger/loggers';
import FirebaseClient from './shared/components/firebase/firebaseClient';
import NavigationBar from './shared/components/navigationBar';
import {TopNavigationProvider} from './shared/components/navigationBar/topNavigationContext';

const logMaster = new LogMaster([new SentryLogger()]);
// Set this globally so that it can be easily picked up by Axios
// this allows us the ability to trace requests back through X-Ray
// annotation.x_request_id CONTAINS "<CORRELATION_ID>"
window.backTraceCorrelationId = logMaster.getCorrelationId();

const firebaseClient = FirebaseClient();

ReactDOM.render(
  <LoggerContext.Provider value={logMaster}>
    <FirebaseNotificationsContext.Provider value={firebaseClient}>
      <TopNavigationProvider>
        <ThemeProvider>
          <NavigationBar />
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <BrowserRouter basename="/ui">
              <Routes />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </TopNavigationProvider>
    </FirebaseNotificationsContext.Provider>
  </LoggerContext.Provider>,
  document.querySelector('#root'), // eslint-disable-line no-undef
);
