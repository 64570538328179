import random from 'lodash/random';
import * as mocks from './mockedReports';

const wait = async (timeout = 1000) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

export const getWidgetData = async (widgetReference, filters) => {
  const waitTime = 1000 + random(-500, 500, false);
  await wait(waitTime);

  const widgetFunction = mocks.getWidgetFunction(widgetReference);

  if (widgetFunction) {
    return widgetFunction(filters);
  }

  throw new Error('Not found');
};
