import React, {PureComponent, Fragment} from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import MapIcon from '@mui/icons-material/Map';
import sortBy from 'lodash/sortBy';
import compose from 'lodash/flowRight';
import CloudLockIcon from '../../shared/images/CloudLockIcon';

import ListItemLink from '../../shared/components/listItemLink';
import BandwidthIndicator from '../../shared/components/bandwidthIndicator';
import {getSiteAddress} from '../../shared/util/sites';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import Tooltip from '../../shared/components/tooltip';
import {getSitesBasePath} from '../../pages/sites/shared/util';

const styles = () => ({
  list: {
    width: '100%',
  },
  itemText: {
    flexBasis: 0,
  },
  itemIcon: {
    justifyContent: 'flex-end',
  },
});

const ItemText = withStyles(styles)(({classes, ...rest}) => (
  <ListItemText className={classes.itemText} {...rest} />
));

const ItemIcon = withStyles(styles)(({classes, ...rest}) => (
  <ListItemIcon className={classes.itemIcon} {...rest} />
));

const ListItemWrapper = withStyles(styles)(
  ({
    site,
    dropdownNav,
    subpath,
    basePath,
    onClick,
    checkboxListMode,
    onCheckboxSelect,
    children,
  }) => (
    <Box>
      {checkboxListMode ? (
        <ListItem
          data-cy="site-list-item"
          sx={(theme) => ({
            padding: theme.spacing(1, 0),
          })}
          divider
          name={site.name}
        >
          <Checkbox
            id={`checkbox-${site.id}`}
            onChange={({target}) => onCheckboxSelect(site, target.checked)}
            checked={site.selected}
            disabled={site.disabled}
          />

          {children}
        </ListItem>
      ) : (
        <ListItemLink
          to={
            subpath
              ? `${basePath}/sites/${site.id}/${subpath}`
              : `${basePath}/sites/${site.id}`
          }
          divider
          name={site.name}
          onClick={() => onClick(site)}
          dropdownNav={dropdownNav}
        >
          {children}
        </ListItemLink>
      )}
    </Box>
  ),
);

class SitesCategoriesList extends PureComponent {
  static Site = ({
    site,
    hasFilter,
    onClick,
    dropdownNav,
    subpath,
    basePath,
    disableSiteDetails,
    checkboxListMode,
    onCheckboxSelect,
  }) => (
    <ListItemWrapper
      site={site}
      dropdownNav={dropdownNav}
      subpath={subpath}
      basePath={basePath}
      onClick={onClick}
      checkboxListMode={checkboxListMode}
      onCheckboxSelect={onCheckboxSelect}
    >
      {!checkboxListMode && (
        <ListItemIcon>
          <BandwidthIndicator
            bandwidth={site.bitrate}
            offline={!site.isOnline}
          />
        </ListItemIcon>
      )}
      <ItemText
        primary={site.name}
        secondary={hasFilter && site.sitePath}
        sx={{color: site.disabled && 'grey.500'}}
      />
      {site.additionalInfo && (
        <ListItemText
          sx={{
            paddingRight: 1,
            paddingLeft: 2,
            textAlign: 'flex-start',
            width: '30%',
          }}
          secondary={site.additionalInfo}
        />
      )}
      {!disableSiteDetails && (
        <Fragment>
          <ItemText secondary={getSiteAddress(site)} />
          <ItemIcon>
            {site.cloudArchiveEnabled ? (
              <Tooltip title="Cloud Archive Activated" placement="right">
                <span>
                  <CloudLockIcon name="CloudArchive" />
                </span>
              </Tooltip>
            ) : (
              <div />
            )}
          </ItemIcon>
        </Fragment>
      )}
    </ListItemWrapper>
  );

  static Category = ({category, onClick, dropdownNav, basePath}) => (
    <ListItemLink
      dropdownNav={dropdownNav}
      to={`${basePath}/sites/category/${category.id}`}
      divider
      name={category.name}
      onClick={() => onClick(category)}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: '0 0 auto',
            display: 'inline-flex',
          }}
        >
          <ListItemIcon>
            <MapIcon />
          </ListItemIcon>
        </Box>
        <Box
          sx={(theme) => ({
            flex: '1 1 auto',
            paddingRight: theme.spacing(2),
          })}
        >
          <Typography variant="body1">{category.name}</Typography>
        </Box>
        <Box
          sx={{
            flex: '0 0 auto',
            marginLeft: 'auto',
          }}
        >
          <Typography variant="body2">{`${
            category.numSites
          } sites`}</Typography>
        </Box>
      </Box>
    </ListItemLink>
  );

  drawList = () => {
    const {
      list,
      onCategoryClick,
      hasFilter,
      dropdownNav,
      dropdownSiteNav,
      subpath,
      disableSiteDetails,
      checkboxListMode,
      onCheckboxSelect,
      currentUser,
    } = this.props;
    const sortedList = sortBy(list, ['type', 'name']);
    const basePath = getSitesBasePath(currentUser);
    return sortedList.map((child) =>
      child.type === 'category' ? (
        <SitesCategoriesList.Category
          dropdownNav={dropdownNav}
          category={child}
          basePath={basePath}
          key={child.id}
          onClick={onCategoryClick}
        />
      ) : (
        <SitesCategoriesList.Site
          dropdownNav={dropdownSiteNav}
          site={child}
          key={child.id}
          hasFilter={hasFilter}
          onClick={onCategoryClick}
          subpath={subpath}
          basePath={basePath}
          disableSiteDetails={disableSiteDetails}
          checkboxListMode={checkboxListMode}
          onCheckboxSelect={onCheckboxSelect}
        />
      ),
    );
  };

  render() {
    const {classes, list, hasFilter, categoriesOnly} = this.props;
    return (
      <List id="siteList" className={classes.list} disablePadding>
        {this.drawList()}
        {categoriesOnly && !hasFilter && !list.length && (
          <ListItem>
            <ItemText primary="No additional Categories" />
          </ListItem>
        )}
        {hasFilter && !list.length && (
          <ListItem>
            <ItemText
              primary={`No ${categoriesOnly ? 'Categories' : 'Sites'} Found`}
            />
          </ListItem>
        )}
      </List>
    );
  }
}

SitesCategoriesList.propTypes = {
  site: PropTypes.shape({}),
  hasFilter: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  dropdownNav: PropTypes.bool,
  dropdownSiteNav: PropTypes.bool,
  subpath: PropTypes.string,
  disableSiteDetails: PropTypes.bool,
  categoriesOnly: PropTypes.bool,
  classes: PropTypes.shape({}),
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onCategoryClick: PropTypes.func,
  checkboxListMode: PropTypes.bool,
  onCheckboxSelect: PropTypes.func,
};

SitesCategoriesList.defaultProps = {
  site: undefined,
  onClick: () => {},
  dropdownNav: false,
  dropdownSiteNav: false,
  categoriesOnly: false,
  disableSiteDetails: false,
  subpath: '',
  classes: undefined,
  onCategoryClick: () => {},
  list: [],
  checkboxListMode: false,
  onCheckboxSelect: () => {},
};

export default compose(
  withStyles(styles),
  withCurrentUser,
)(SitesCategoriesList);
