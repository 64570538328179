import React, {Fragment, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import VideocamIcon from '@mui/icons-material/Videocam';
import {Typography} from '@mui/material';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';

import CameraConfigurationDialog from './CameraConfigurationDialog';
import SectionTitleBar from '../SectionTitleBar';
import {useDialog} from '../../../../shared/hooks';
import CameraEnablementDialog from './CameraEnablementDialog';
import {
  getEnabledCamerasBySite,
  saveCameraConfiguration,
  updateCameraConfiguration,
} from '../../../../api/alarms';
import {getStatusFromAppliance, pushConfig} from '../../../../api/appliances';
import {versionsMeetMinimum} from '../../../../shared/util/appliances';
import Spinner from '../../../../shared/components/spinner';
import {CameraMaskEnum} from './shared/const';

const checkVideoAlarmSupport = async (applianceIds) => {
  const appliances = applianceIds.split(',');
  const applianceStatuses = await Promise.all(
    appliances.map((id) => getStatusFromAppliance(id)),
  ).catch((e) => ({error: e}));

  return versionsMeetMinimum(applianceStatuses, '5.2.0', true);
};

const initialCameraConfigurationState = {
  isCameraEdit: false,
  isFetching: false,
  configSaveSuccess: false,
  configSaveError: false,
  cameraUpdate: false,
  cameraToEnable: {},
  camerasList: [],
  videoAlarmSupported: true,
};
const cameraConfigurationReducer = (state, {type, payload = {}, key = ''}) => {
  switch (type) {
    case 'update':
      return {...state, ...payload};
    case 'toggle': {
      const update = {[key]: !state[key]};
      return {
        ...state,
        ...update,
      };
    }
    case 'reset':
      return {
        ...state,
        ...omit(initialCameraConfigurationState, 'camerasList'),
      };
    default:
      return state;
  }
};

const CameraConfigurationSection = (props) => {
  const {
    siteCameras,
    selectedSite,
    snackbar,
    onSchedulesRefresh,
    currentUser,
    existingSiteSchedule,
    camerasConfigurationOpen,
    onCamerasConfigurationOpen,
    onCamerasConfigurationClose,
    sspProMonitoring,
  } = props;

  const [
    cameraEnablementDialogOpen,
    handleOpenCameraEnablementDialog,
    handleCloseCameraEnablementDialog,
  ] = useDialog();

  const [
    {
      isCameraEdit,
      isFetching,
      configSaveSuccess,
      configSaveError,
      cameraUpdate,
      cameraToEnable,
      camerasList,
      videoAlarmSupported,
    },
    dispatch,
  ] = useReducer(cameraConfigurationReducer, initialCameraConfigurationState);

  const initializeCameraForCalipsa = async (camera) => {
    try {
      // initialize camera for calipsa
      const defaultMask = CameraMaskEnum.NONE; // mask should not be prefilled when enabling a new SSP camera
      const {calipsaTestId} = await saveCameraConfiguration(
        selectedSite.id,
        camera.id,
        {
          mask: defaultMask,
          enabled: false,
        },
      );
      camera.calipsaTestId = calipsaTestId; // eslint-disable-line no-param-reassign
      camera.motionAlarmMask = defaultMask; // eslint-disable-line no-param-reassign
    } catch (e) {
      dispatch({
        type: 'update',
        payload: {configSaveError: true},
      });
      snackbar.error(
        `Failed to initialize camera. Please try again or contact support if the issue persists.`,
      );
    }
  };

  const handlePushConfig = async () => {
    const applianceIds = [
      ...new Set(siteCameras.map((cam) => cam.applianceId)),
    ];
    await Promise.all(applianceIds.map((id) => pushConfig(id)));
  };

  const disableCamera = async (camera) => {
    try {
      await updateCameraConfiguration(selectedSite.id, camera.id, {
        set: {enabled: !camera.motionAlarmEnabled},
      });
      await onSchedulesRefresh();
      snackbar.success(`Camera updated successfully.`);
    } catch (e) {
      snackbar.error(
        `Failed to update camera. Please try again or contact support if the issue persists.`,
      );
    }
  };

  const handleCameraEnablementClick = async (camera) => {
    if (!camera.calipsaTestId) {
      await initializeCameraForCalipsa(camera);
    }

    if (!camera.motionAlarmEnabled) {
      dispatch({
        type: 'update',
        payload: {cameraToEnable: camera},
      });
      handleOpenCameraEnablementDialog();
    } else {
      await disableCamera(camera);
      await handlePushConfig();
    }
  };

  const handleSaveCameraEnablement = async (
    {siteId, cameraId, cameraConfigData},
    onSuccess,
    onError,
  ) => {
    try {
      await updateCameraConfiguration(siteId, cameraId, {
        set: cameraConfigData,
      });
      await handlePushConfig();

      dispatch({
        type: 'toggle',
        key: 'cameraUpdate',
      });
      await onSchedulesRefresh();
      dispatch({
        type: 'update',
        payload: {configSaveSuccess: true, configSaveError: false},
      });
      onSuccess();
    } catch (e) {
      dispatch({
        type: 'update',
        payload: {configSaveError: true},
      });
      onError();
    }
  };

  const handleEditCamera = (camera) => {
    dispatch({
      type: 'update',
      payload: {isCameraEdit: true, cameraToEnable: camera},
    });
    handleOpenCameraEnablementDialog();
  };

  useEffect(
    () => {
      const getCamerasExtraData = async () => {
        const applianceIds = [
          ...new Set(siteCameras.map((cam) => cam.applianceId)),
        ].join(',');

        dispatch({
          type: 'toggle',
          key: 'isFetching',
        });

        const applianceVersionSupported = await checkVideoAlarmSupport(
          applianceIds,
        );
        if (!applianceVersionSupported) {
          dispatch({
            type: 'update',
            payload: {
              videoAlarmSupported: false,
              isFetching: false,
              camerasList: [],
            },
          });
          return;
        }
        dispatch({
          type: 'update',
          payload: {videoAlarmSupported: true},
        });

        const motionAlarmEnabledCameras = await getEnabledCamerasBySite(
          selectedSite.id,
        );
        const motionAlarmEnabledCameraByCameraId = keyBy(
          motionAlarmEnabledCameras.results,
          'cameraId',
        );

        const filteredCamerasWithEnabledField = [];
        siteCameras.forEach((camera) => {
          if (camera.enabled && camera.recordMode !== 'None') {
            filteredCamerasWithEnabledField.push({
              ...camera,
              motionAlarmEnabled:
                motionAlarmEnabledCameraByCameraId?.[camera.id]?.configuration
                  ?.motionAlarmEnabled ?? false,
              motionAlarmMask:
                motionAlarmEnabledCameraByCameraId?.[camera.id]?.configuration
                  ?.motionAlarmMask,
              calipsaTestId:
                motionAlarmEnabledCameraByCameraId?.[camera.id]?.configuration
                  ?.calipsaTestId,
            });
          }
        });

        const sortedCameras = sortBy(filteredCamerasWithEnabledField, [
          'displayOrder',
          'channel',
        ]);
        dispatch({
          type: 'update',
          payload: {camerasList: [...sortedCameras], isFetching: false},
        });
      };
      if (siteCameras) {
        getCamerasExtraData();
      }
    },
    [selectedSite, cameraUpdate, siteCameras],
  );

  const displayCamerasSectionSubheader = () => {
    if (isFetching || siteCameras == null) {
      return <Spinner size={40} color="primary" />;
    }
    if (!videoAlarmSupported) {
      return (
        <Typography variant="subtitle2">
          The appliance software version does not support video alarms or is
          unknown, please contact support for assistance.
        </Typography>
      );
    }

    return (
      <div>
        <Typography variant="subtitle2">Click Edit to enable camera</Typography>
        <br />
        <Typography variant="subtitle2">
          {camerasList?.filter((c) => c.motionAlarmEnabled).length} out of{' '}
          {camerasList?.length} enabled
        </Typography>
      </div>
    );
  };

  return (
    <Fragment>
      <div name="camera-configuration-edit-button">
        <SectionTitleBar
          title="Cameras"
          onClick={onCamerasConfigurationOpen}
          icon={<VideocamIcon />}
          isActionIconVisible={
            videoAlarmSupported && !isFetching && !!siteCameras
          }
        />
        {displayCamerasSectionSubheader()}
      </div>
      <CameraConfigurationDialog
        selectedSite={selectedSite}
        currentUser={currentUser}
        existingSiteSchedule={existingSiteSchedule}
        handleCameraEnablementClick={handleCameraEnablementClick}
        handleEditCamera={handleEditCamera}
        camerasList={camerasList}
        isVideoAlarmSupported={videoAlarmSupported}
        camerasConfigurationOpen={camerasConfigurationOpen}
        onCamerasConfigurationClose={onCamerasConfigurationClose}
        dispatch={dispatch}
        sspProMonitoring={sspProMonitoring}
      />
      <CameraEnablementDialog
        isCameraEdit={isCameraEdit}
        open={cameraEnablementDialogOpen}
        onCancel={() => {
          handleCloseCameraEnablementDialog();
          dispatch({type: 'reset'});
        }}
        camera={cameraToEnable}
        existingSiteSchedule={existingSiteSchedule}
        onSave={handleSaveCameraEnablement}
        cameraUpdateStatus={{configSaveSuccess, configSaveError}}
      />
    </Fragment>
  );
};

CameraConfigurationSection.propTypes = {
  siteCameras: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSite: PropTypes.shape({}).isRequired,
  snackbar: PropTypes.shape({}).isRequired,
  onSchedulesRefresh: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  camerasConfigurationOpen: PropTypes.bool.isRequired,
  onCamerasConfigurationOpen: PropTypes.func.isRequired,
  onCamerasConfigurationClose: PropTypes.func.isRequired,
  existingSiteSchedule: PropTypes.shape({}),
  sspProMonitoring: PropTypes.bool.isRequired,
};

CameraConfigurationSection.defaultProps = {
  siteCameras: null,
  existingSiteSchedule: {},
};

export default CameraConfigurationSection;
